<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 mb-6 w-full border-b-2 border-b-kamadoor-orange font-sans text-4xl text-white"
  >
    Drzwi do kamienicy - Drzwi zabytkowe
  </span>

  <span class="mb-6"
    >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
    pisemnego zapytania przy użyciu
    <a
      href="kontakt/formularz-kontaktowy"
      class="text-kamadoor-orange hover:underline"
      >FORMULARZA KONTAKTOWEGO.</a
    ></span
  >

  <div
    class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 desktopSmall:grid-cols-4 justify-between gap-y-8"
  >
    @for (number of numbers; track number) {
    <kamadoor-image-preview
      [src]="'assets/offer/tenement/' + number.toString() + '.JPG'"
      [label]="'DK-DZ-' + number.toString().padStart(2, '0')"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    }
  </div>
  <div
    class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 justify-between gap-y-8 mt-10"
  >
    <kamadoor-image-preview
      src="assets/offer/tenement/49.JPG"
      label="DK-DZ-49"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    <kamadoor-image-preview
      src="assets/offer/tenement/50.JPG"
      label="DK-DZ-50"
      labelClass="pt-4"
    ></kamadoor-image-preview>
  </div>
</div>
