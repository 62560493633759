<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Drzwi JAGDOR / JAGRAS
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span>
      Oferowane przez naszą firmę drzwi JAGDOR oraz JAGRAS to drzwi bezprzylgowe
      otwierane standardowo oraz drzwi otwierane przez ościeżnicę (rewersyjne) i
      to zarówno na ościeżnicy regulowanej, jak i ukrytej w ścianie "SKRIVEN",
      drzwi przesuwne naścienne oraz przesuwne chowane w ścianie, a także drzwi
      łamane.
    </span>
    <span
      >Do dyspozycji naszego Klienta oddajemy drzwi wykonane w różnych wzorach i
      kolorach fornirów, a także wybranych kolorach RAL. Oferujemy szeroką gamę
      modeli zarówno drzwi pełnych, przeszklonych, z frezowaniem klasycznym,
      nowoczesnym, a także z elementami ozdobnymi, które stanowią bonie
      aluminiowe</span
    >
    <span
      >Pełna oferta drzwi jest dostępna w katalogu
      <a
        class="text-kamadoor-orange hover:underline"
        href="https://www.jagras.eu/katalogi/"
        >katalogu.</a
      ></span
    >

    <span
      >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
      pisemnego zapytania przy użyciu
      <a
        href="kontakt/formularz-kontaktowy"
        class="text-kamadoor-orange hover:underline"
        >FORMULARZA KONTAKTOWEGO.</a
      ></span
    >
  </div>

  <div class="mt-10 flex flex-col w-full">
    <span class="text-white font-semibold pb-2"
      >Poniżej przedstawiamy wybrane modele tego producenta:</span
    >
  </div>

  <div
    class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 desktopSmall:grid-cols-4 justify-between gap-y-8"
  >
    @for (number of numbers; track number) {
    <kamadoor-image-preview
      [src]="
        'assets/offer/internal/jagdor-jagras/DWJ-' +
        number.toString().padStart(2, '0') +
        '.jpg'
      "
      [label]="'DWJ-' + number.toString().padStart(2, '0')"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    }
  </div>
</div>
