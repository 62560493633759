<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Drzwi zewnętrzne BARAŃSKI
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span>
      Drzwi zewnętrzne <b>BARAŃSKI</b> to wysokiej jakości ciepłe drzwi
      <b>DREWNIANE</b> obłogowane dębem występujące w kilku wariantach
      konstrukcyjnych:
      <ul class="list-disc ml-10 mt-4 flex flex-col gap-4">
        <li>
          THERMO WOOD PŁYTOWE bezprzylgowe o grubości skrzydła 90mm<br />współczynnik
          przenikania ciepła U=0,7 W/(M²*K)
        </li>
        <li>
          THERMO WOOD PŁYTOWE przylgowe o grubości skrzydła 78mm<br />współczynnik
          przenikania ciepła U=0,85 W/(M²*K)
        </li>
        <li>
          PŁYCINOWE przylgowe o grubości skrzydła 78mm<br />współczynnik
          przenikania ciepła U=1,1 W/(M²*K)
        </li>
      </ul></span
    >
    <span>Oferowane drzwi produkowane są <b>na wymiar.</b></span>
    <span
      >Pełna oferta drzwi jest dostępna w
      <a
        class="text-kamadoor-orange hover:underline"
        href="https://www.baranskidrzwi.pl/katalogi/"
        >katalogu.</a
      ></span
    >

    <span
      >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
      pisemnego zapytania przy użyciu
      <a
        href="kontakt/formularz-kontaktowy"
        class="text-kamadoor-orange hover:underline"
        >FORMULARZA KONTAKTOWEGO.</a
      ></span
    >
  </div>

  <div class="mt-10 flex flex-col w-full">
    <span class="text-white font-semibold pb-2"
      >Poniżej przedstawiamy wybrane modele tego producenta:</span
    >
  </div>

  <div
    class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 desktopSmall:grid-cols-4 justify-between gap-y-8"
  >
    @for (number of numbers; track number) {
    <kamadoor-image-preview
      [src]="
        'assets/offer/external/baranski/DZB-' +
        number.toString().padStart(2, '0') +
        '.JPG'
      "
      [label]="'DZB-' + number.toString().padStart(2, '0')"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    }
  </div>
  <div
    class="grid grid-flow-row grid-cols-1 desktopSmall:grid-cols-3 justify-between gap-y-8 mt-10"
  >
    <kamadoor-image-preview
      src="assets/offer/external/baranski/DZB-21.JPG"
      label="DZB-21"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    <kamadoor-image-preview
      src="assets/offer/external/baranski/DZB-22.JPG"
      label="DZB-22"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    <kamadoor-image-preview
      src="assets/offer/external/baranski/DZB-23.JPG"
      label="DZB-23"
      labelClass="pt-4"
    ></kamadoor-image-preview>
  </div>
</div>
