<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Klamki do drzwi
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span>
      Oferta naszego salonu sprzedaży jest bogata w różnego rodzaju klamki, w
      tym te o klasycznych formach, jak również nowoczesne i unikatowe. Dobrze
      dobrana klamka powinna być dopasowana zarówno do samych drzwi, jak i
      całego wnętrza. Oferujemy szeroką gamę wzorów i odcieni, tak aby każdy
      klient mógł wybrać opowiednie dla siebie klamki.
    </span>

    <div class="flex flex-col">
      <span class="text-2xl">Producenci</span
      ><span class="text-sm mb-4 text-kamadoor-orange"
        >Kliknij w logo, aby przejść do strony producenta</span
      >
    </div>
  </div>

  <p-dataView
    class="flex w-full bg-kamadoor-light-gray justify-center desktopSmall:justify-normal"
    [value]="logos"
    layout="grid"
  >
    <ng-template let-product pTemplate="grid" let-items>
      <div
        class="grid grid-cols-1 tablet:grid-cols-2 desktopSmall:grid-cols-4 gap-4 bg-kamadoor-light-gray"
      >
        @for (item of items; track item.image) {
        <a
          [href]="item.url"
          class="bg-white border border-white flex flex-col h-full w-[200px] transition ease-in-out duration-300 hover:-translate-y-1 hover:scale-110 text-center items-center"
        >
          <img [src]="item.image" />
        </a>
        }
      </div>
    </ng-template>
  </p-dataView>

  <span class="mt-6"
    >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
    pisemnego zapytania przy użyciu
    <a
      href="kontakt/formularz-kontaktowy"
      class="text-kamadoor-orange hover:underline"
      >FORMULARZA KONTAKTOWEGO.</a
    ></span
  >

  <div class="mt-10 flex flex-col w-full">
    <span class="text-white font-semibold pb-2"
      >Poniżej przedstawiamy wybrane modele klamek:</span
    >
  </div>

  <div
    class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 desktopMenuMedium:grid-cols-4 justify-between gap-y-8"
  >
    @for (handle of handles; track handle) {
    <kamadoor-image-preview
      class="h-[200px]"
      [src]="'assets/offer/handles-boards/handles/' + handle + '.jpg'"
      [label]="handle"
      labelClass="pt-4"
      [maxH]="200"
    ></kamadoor-image-preview>
    }
  </div>
</div>
