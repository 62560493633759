<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    O nas
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span>
      KamaDoor to nowoczesny Salon Wystawowy, który działa od 2012 roku i
      oferuje swe usługi oraz produkty głównie klientom aglomeracji poznańskiej.
      W swej ofercie posiadamy szeroką gamę drzwi, ze szczególnym uwzględnieniem
      drzwi wewnętrznych i zewnętrznych oraz Drzwi Szklanych, które wykonujemy
      na indywidualne zamówienie klienta w formie drzwi przesuwnych, uchylnych
      oraz wahadłowych w stylu Loft - Industrial.
    </span>

    <span>
      Jako jedni z nielicznych, podejmujemy się również produkcji drzwi
      drewnianych, zarówno wewnętrznych jak i zewnętrznych, według
      niestandardowych wymiarów oraz uzgodnień związanych z typem ich wykonania
      oraz kolorystyką.
    </span>

    <span
      >W naszym salonie, klienci znajdą także drzwi różniące się między sobą
      sposobem ich mocowania z futryną, gdzie poza zawiasami standardowymi,
      posiadamy także drzwi bezprzylgowe a także drzwi ukryte, bez widocznej
      futryny.</span
    >

    <span
      >Szklane zabudowy do łazienek, wykonujemy w wielu wariantach
      konstrukcyjnych oraz wymiarowych.</span
    >

    <span
      >Asortyment naszego salonu, uzupełniają artykuły wyposażenia wnętrz w
      postaci paneli podłogowych firmy Parador, listew przypodłogowych oraz
      klamek do drzwi.
    </span>

    <span
      >Atutem naszej firmy, jest duże doświadczenie w branży stolarki drzwiowej,
      które pozwala w sposób kompetentny doradzić i pomóc klientom w podjęciu
      możliwie najkorzystniejszej decyzji w zakresie zakupów, obejmujących swym
      obszarem naszą ofertę produktową.</span
    >

    <div
      class="flex flex-col desktopSmall:flex-row mt-4 gap-y-8 desktopSmall:gap-y-0"
    >
      <div class="flex flex-col gap-6 w-full desktopSmall:w-1/2">
        <span class="text-3xl">Nasze atuty</span>
        <span
          >Bezsprzecznie wybierając naszą firmę, wybieracie Państwo:
          <ul class="list-disc ml-10 mt-2 flex flex-col gap-2">
            <li>wysokiej jakości produkty w bardzo atrakcyjnych cenach,</li>
            <li>kompetentną obsługę i doradztwo,</li>
            <li>profesjonalny montaż,</li>
            <li>szybkie terminy produkcji,</li>
            <li>możliwość realizowania indywidualnych rozwiązań i pomysłów.</li>
          </ul></span
        >
      </div>
      <div class="flex flex-col gap-6 w-full desktopSmall:w-1/2 text-center">
        <span class="text-3xl"
          >Szczególnie gorąco zapraszamy do współpracy:</span
        >
        <span
          >Architektów, Dekoratorów i Projektantów Wnętrz , którzy przy naszej
          pomocy będą mogli przeprowadzić śmiałe, nietypowe i nowoczesne
          realizacje wyposażenia wnętrz. Gwarantujemy możliwość realizowania
          indywidualnych rozwiązań i pomysłów.</span
        >
      </div>
    </div>
  </div>

  <div
    class="grid grid-flow-row grid-cols-1 desktopSmall:grid-cols-3 justify-between gap-y-4 desktopSmall:gap-y-8"
  >
    <kamadoor-image-preview
      class="h-[400px]"
      [showBorder]="false"
      src="assets/about-us/about-us-1.jpg"
    />
    <kamadoor-image-preview
      class="h-[400px]"
      [showBorder]="false"
      src="assets/about-us/about-us-2.jpg"
    />
    <kamadoor-image-preview
      class="h-[400px]"
      [showBorder]="false"
      src="assets/about-us/about-us-3.jpg"
    />
    <kamadoor-image-preview
      class="h-[400px]"
      [showBorder]="false"
      src="assets/about-us/about-us-4.jpg"
    />
    <kamadoor-image-preview
      class="h-[400px]"
      [showBorder]="false"
      src="assets/about-us/about-us-5.jpg"
    />
    <kamadoor-image-preview
      class="h-[400px]"
      [showBorder]="false"
      src="assets/about-us/about-us-6.jpg"
    />
  </div>
</div>
