import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from '@pages/not-found/not-found.component';
import { MainPageComponent } from '@pages/main-page/main-page.component';
import { AccessoriesComponent } from '@pages/offer/internal-doors/accessories/accessories.component';
import { InternalDoorsComponent } from '@pages/offer/internal-doors/internal-doors.component';
import { ExternalDoorsComponent } from '@pages/offer/external-doors/external-doors.component';
import { ApartmentInternalDoorsComponent } from '@pages/offer/apartment-internal-doors/apartment-internal-doors.component';
import { LoftDoorsComponent } from '@pages/offer/loft-doors/loft-doors.component';
import { HiddenDoorsComponent } from '@pages/offer/hidden-doors/hidden-doors.component';
import { SlidingDoorsComponent } from '@pages/offer/sliding-doors/sliding-doors.component';
import { ShowersComponent } from '@pages/offer/showers/showers.component';
import { RealizationsComponent } from '@pages/realizations/realizations.component';
import { GuideComponent } from '@pages/guide/guide.component';
import { AboutUsComponent } from '@pages/about-us/about-us.component';
import { ContactComponent } from '@pages/contact/contact.component';
import { ExternalBaranskiComponent } from '@pages/offer/external-doors/baranski/baranski.component';
import { ExternalDallasComponent } from '@pages/offer/external-doors/dallas/dallas.component';
import { ExternalKMTComponent } from '@pages/offer/external-doors/kmt/kmt.component';
import { ApartmentInternalBaranskiComponent } from '@pages/offer/apartment-internal-doors/baranski/baranski.component';
import { ApartmentInternalDallasComponent } from '@pages/offer/apartment-internal-doors/dallas/dallas.component';
import { InternalBaranskiComponent } from '@pages/offer/internal-doors/baranski/baranski.component';
import { InternalDallasComponent } from '@pages/offer/internal-doors/dallas/dallas.component';
import { InternalFrenchComponent } from '@pages/offer/internal-doors/french/french.component';
import { InternalJagdorJagrasComponent } from '@pages/offer/internal-doors/jagdor-jagras/jagdor-jagras.component';
import { InternalOldFrameComponent } from '@pages/offer/internal-doors/old-frame/old-frame.component';
import { InternalVosterComponent } from '@pages/offer/internal-doors/voster/voster.component';
import { InternalDreComponent } from '@pages/offer/internal-doors/dre/dre.component';
import { ApartmentInternalFireproofComponent } from '@pages/offer/apartment-internal-doors/fireproof/fireproof.component';
import { LoftLoftComponent } from '@pages/offer/loft-doors/loft/loft.component';
import { LoftGlassComponent } from '@pages/offer/loft-doors/glass/glass.component';
import { LoftVestibuleComponent } from '@pages/offer/loft-doors/vestibule/vestibule.component';
import { AccessoriesBoardsComponent } from '@pages/offer/internal-doors/accessories/boards/boards.component';
import { AccessoriesHandlesComponent } from '@pages/offer/internal-doors/accessories/handles/handles.component';
import { SlidingGlassComponent } from '@pages/offer/sliding-doors/glass/glass.component';
import { SlidingWoodenComponent } from '@pages/offer/sliding-doors/wooden/wooden.component';
import { SlidingFoldingComponent } from '@pages/offer/sliding-doors/folding/folding.component';
import { TenementDoorsComponent } from '@pages/offer/tenement-doors/tenement-doors.component';
import { DreAuriComponent } from '@pages/offer/internal-doors/dre/auri/auri.component';
import { DreBergeComponent } from '@pages/offer/internal-doors/dre/berge/berge.component';
import { DreBinitoComponent } from '@pages/offer/internal-doors/dre/binito/binito.component';
import { DreNestorComponent } from '@pages/offer/internal-doors/dre/nestor/nestor.component';
import { DreNovaComponent } from '@pages/offer/internal-doors/dre/nova/nova.component';
import { DreScalaComponent } from '@pages/offer/internal-doors/dre/scala/scala.component';
import { DreShinyComponent } from '@pages/offer/internal-doors/dre/shiny/shiny.component';
import { DreStandardComponent } from '@pages/offer/internal-doors/dre/standard/standard.component';
import { DreUniComponent } from '@pages/offer/internal-doors/dre/uni/uni.component';
import { DreVetroDComponent } from '@pages/offer/internal-doors/dre/vetro-d/vetro-d.component';
import { DreVetroEComponent } from '@pages/offer/internal-doors/dre/vetro-e/vetro-e.component';
import { DreWoodComponent } from '@pages/offer/internal-doors/dre/wood/wood.component';
import { ContactFormComponent } from '@pages/contact/form/form.component';

const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
  },
  {
    path: 'drzwi-wewnetrzne',
    component: InternalDoorsComponent,
  },
  {
    path: 'drzwi-wewnetrzne-baranski',
    component: InternalBaranskiComponent,
  },
  {
    path: 'drzwi-wewnetrzne-dallas',
    component: InternalDallasComponent,
  },
  {
    path: 'drzwi-wewnetrzne-dre',
    component: InternalDreComponent,
  },
  {
    path: 'drzwi-wewnetrzne-dre-auri',
    component: DreAuriComponent,
  },
  {
    path: 'drzwi-wewnetrzne-dre-berge',
    component: DreBergeComponent,
  },
  {
    path: 'drzwi-wewnetrzne-dre-binito',
    component: DreBinitoComponent,
  },
  {
    path: 'drzwi-wewnetrzne-dre-nestor',
    component: DreNestorComponent,
  },
  {
    path: 'drzwi-wewnetrzne-dre-nova',
    component: DreNovaComponent,
  },
  {
    path: 'drzwi-wewnetrzne-dre-scala',
    component: DreScalaComponent,
  },
  {
    path: 'drzwi-wewnetrzne-dre-polyskowe',
    component: DreShinyComponent,
  },
  {
    path: 'drzwi-wewnetrzne-dre-standard',
    component: DreStandardComponent,
  },
  {
    path: 'drzwi-wewnetrzne-dre-uni',
    component: DreUniComponent,
  },
  {
    path: 'drzwi-wewnetrzne-dre-vetro-d',
    component: DreVetroDComponent,
  },
  {
    path: 'drzwi-wewnetrzne-dre-vetro-e',
    component: DreVetroEComponent,
  },
  {
    path: 'drzwi-wewnetrzne-dre-wood',
    component: DreWoodComponent,
  },
  {
    path: 'drzwi-wewnetrzne-francuskie',
    component: InternalFrenchComponent,
  },
  {
    path: 'drzwi-wewnetrzne-jagdor-jagras',
    component: InternalJagdorJagrasComponent,
  },
  {
    path: 'drzwi-wewnetrzne-stara-metalowa-oscieznica',
    component: InternalOldFrameComponent,
  },
  {
    path: 'drzwi-wewnetrzne-voster',
    component: InternalVosterComponent,
  },
  {
    path: 'drzwi-wewnetrzne-akcesoria',
    component: AccessoriesComponent,
  },
  {
    path: 'listwy-przypodlogowe',
    component: AccessoriesBoardsComponent,
  },
  {
    path: 'klamki-do-drzwi',
    component: AccessoriesHandlesComponent,
  },
  {
    path: 'drzwi-zewnetrzne',
    component: ExternalDoorsComponent,
  },
  {
    path: 'drzwi-zewnetrzne-baranski',
    component: ExternalBaranskiComponent,
  },
  {
    path: 'drzwi-zewnetrzne-dallas',
    component: ExternalDallasComponent,
  },
  {
    path: 'drzwi-zewnetrzne-kmt',
    component: ExternalKMTComponent,
  },
  {
    path: 'drzwi-wejsciowe-do-mieszkania',
    component: ApartmentInternalDoorsComponent,
  },
  {
    path: 'drzwi-wejsciowe-do-mieszkania-baranski',
    component: ApartmentInternalBaranskiComponent,
  },
  {
    path: 'drzwi-wejsciowe-do-mieszkania-dallas',
    component: ApartmentInternalDallasComponent,
  },
  {
    path: 'drzwi-wejsciowe-do-mieszkania-przeciwpozarowe',
    component: ApartmentInternalFireproofComponent,
  },
  {
    path: 'drzwi-loftowe-drzwi-szklane',
    component: LoftDoorsComponent,
  },
  {
    path: 'drzwi-loftowe',
    component: LoftLoftComponent,
  },
  {
    path: 'drzwi-szklane',
    component: LoftGlassComponent,
  },
  {
    path: 'drzwi-szklane-do-wiatrolapu',
    component: LoftVestibuleComponent,
  },
  {
    path: 'drzwi-ukryte',
    component: HiddenDoorsComponent,
  },
  {
    path: 'drzwi-przesuwne',
    component: SlidingDoorsComponent,
  },
  {
    path: 'drzwi-przesuwne-szklane',
    component: SlidingGlassComponent,
  },
  {
    path: 'drzwi-przesuwne-drewniane',
    component: SlidingWoodenComponent,
  },
  {
    path: 'drzwi-lamane-drzwi-skladane',
    component: SlidingFoldingComponent,
  },
  {
    path: 'scianki-prysznicowe-kabiny-prysznicowe',
    component: ShowersComponent,
  },
  {
    path: 'drzwi-do-kamienicy-drzwi-zabytkowe',
    component: TenementDoorsComponent,
  },
  {
    path: 'realizacje',
    component: RealizationsComponent,
  },
  {
    path: 'poradnik',
    component: GuideComponent,
  },
  {
    path: 'o-nas',
    component: AboutUsComponent,
  },
  {
    path: 'kontakt',
    children: [
      {
        path: '',
        component: ContactComponent,
      },
      {
        path: 'formularz-kontaktowy',
        component: ContactFormComponent,
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
