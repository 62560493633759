import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-loft-loft',
  templateUrl: './loft.component.html',
  styleUrls: ['./loft.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class LoftLoftComponent {
  numbers = Array.from({ length: 116 }, (_, i) => i + 1);

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi loftowe | KamaDOOR');
  }
}
