import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@root/src/app/components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-tenement-doors',
  templateUrl: './tenement-doors.component.html',
  styleUrls: ['./tenement-doors.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class TenementDoorsComponent {
  numbers = Array.from({ length: 48 }, (_, i) => i + 1);

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle(
      'Drzwi do kamienicy - Drzwi zabytkowe | KamaDOOR'
    );
  }
}
