import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-accessories-boards',
  templateUrl: './boards.component.html',
  styleUrls: ['./boards.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class AccessoriesBoardsComponent {
  numbers = Array.from({ length: 12 }, (_, i) => i + 1);

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Listwy przypodłogowe | KamaDOOR');
  }
}
