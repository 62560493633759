import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-internal-old-frame',
  templateUrl: './old-frame.component.html',
  styleUrls: ['./old-frame.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class InternalOldFrameComponent {
  numbers = Array.from({ length: 16 }, (_, i) => i + 1);

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle(
      'Drzwi wewnętrzne na starą metalową ościeżnicę | KamaDOOR'
    );
  }
}
