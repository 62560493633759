<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 mb-6 w-full border-b-2 border-b-kamadoor-orange font-sans text-4xl text-white"
  >
    Klamki do drzwi / Listwy przypodłogowe
  </span>

  <p-dataView [value]="offerItems" layout="grid">
    <ng-template let-product pTemplate="grid" let-items>
      <div
        class="grid grid-flow-row justify-items-center grid-cols-1 mobileLarge:grid-cols-2 desktopSmall:grid-cols-3 desktop:grid-cols-4 gap-x-4 gap-y-4 bg-kamadoor-light-gray"
      >
        @for (item of items; track item.name) {
        <kamadoor-offer-button
          [label]="item.name"
          [src]="'assets/offer/handles-boards/' + item.image"
          [url]="item.url"
        ></kamadoor-offer-button>
        }
      </div>
    </ng-template>
  </p-dataView>
</div>
