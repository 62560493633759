import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { DataViewModule } from 'primeng/dataview';

import { OfferButtonComponent } from '@components/offer-button/offer-button.component';

@Component({
  selector: 'kamadoor-loft-doors',
  templateUrl: './loft-doors.component.html',
  styleUrls: ['./loft-doors.component.scss'],
  imports: [DataViewModule, OfferButtonComponent],
  standalone: true,
})
export class LoftDoorsComponent {
  offerItems = [
    {
      name: 'DRZWI LOFTOWE',
      image: 'loft.jpg',
      url: 'drzwi-loftowe',
    },
    {
      name: 'DRZWI SZKLANE',
      image: 'glass.jpg',
      url: 'drzwi-szklane',
    },
    {
      name: 'DRZWI SZKLANE\nDO WIATROŁAPU',
      image: 'vestibule.jpg',
      url: 'drzwi-szklane-do-wiatrolapu',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi loftowe - Drzwi szklane | KamaDOOR');
  }
}
