import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ContactFormComponent } from './form/form.component';

@Component({
  selector: 'kamadoor-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  standalone: true,
  imports: [ContactFormComponent],
})
export class ContactComponent {
  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Kontakt | KamaDOOR');
  }
}
