<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full mb-6 border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Warto wiedzieć
  </span>

  <p-accordion [activeIndex]="0">
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Jakie są rodzaje oklein?"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span class="text-xl">Sztuczne:</span>
        <span
          >Pojęciem okleiny sztucznej określa się wszystkie arkuszowe produkty z
          tworzyw sztucznych, które naklejane lub naprasowywane na płaskie
          powierzchnie tworzyw drzewnych lub drewnopodobnych w celu imitacji lub
          zastąpienia oklein drewnianych - naturalnych.</span
        >
        <span
          >Asortyment oklein sztucznych jest bardzo szeroki co jest spowodowane
          z jednej strony coraz większym deficytem oklein drewnianych, a z
          drugiej strony właściwościami oklein sztucznych, które w coraz
          większym stopniu w stosunku do oklein drewnianych, są w stanie spełnić
          wymagania użytkowe.</span
        >
        <span>
          <b>Okleina 3D:</b> Charakteryzuje się przede wszystkim przestrzenny
          charakter i uwypuklenia, które nie tylko efektownie wyglądają, ale też
          są odporne na zabrudzenia – nie widać na niej śladów palców. Do
          złudzenia przypomina ona naturalne drewno, a przy tym jest niezwykle
          wytrzymała i dobrze prezentuje się w nowoczesnych wnętrzach.
        </span>
        <span>
          <b>Okleina CPL: (Czyli Eco-fornir).</b> Jest wykorzystywana nie tylko
          w pomieszczeniach mieszkalnych, ale też w budynkach użyteczności
          publicznej ze względu na zwiększoną odporność na uszkodzenia. Wykonana
          jest z kilku warstw impregnowanych papierów poddanych działaniu
          wysokiego ciśnienia i odpowiedniej temperatury. Całość zabezpieczona
          jest odpowiednim lakierem, który chroni przed promieniami UV i
          ścieraniem się podczas codziennego użytkowania.
        </span>
        <span class="text-xl">Naturalne:</span>
        <span
          ><b>Fornir:</b> często określa się go jako okleinę naturalną.
          Otrzymywany jest w wyniku skrawania drewna o dużych średnicach na
          cienkie płaty o grubościach 0,1-0,5 mm. Drzwi fornirowane są łudząco
          podobne do tych z naturalnego drewna i dla laika odróżnienie jednych
          od drugich nie jest łatwe. Fornir zapewnia ponadprzeciętną estetykę
          skrzydła i szlachetny charakter uzyskany dzięki niepowtarzalności
          słojów. Cechuje się większą wytrzymałością niż okleina sztuczna, ale
          jest droższy w zakupie.</span
        >
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Panele winylowe"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span
          >Podłogi winylowe, wyróżniają się bardzo wysoką trwałością, komfortem
          użytkowania i stylowym wzornictwem. Ze względu na swe właściwości
          techniczne, podłogi winylowe pozostają łatwe w pielęgnacji i cechują
          się wysoką odpornością na wilgoć oraz substancje chemiczne. Doskonale
          się prezentują zarówno w łazience jak i kuchni. Specjalna powłoka,
          naniesiona na powierzchnię podłóg sprawia, że są one szczególnie
          polecane dla osób odczuwających alergie.</span
        >
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Panele laminowane"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span
          >Są tańszym odpowiednikiem podłóg drewnianych a jednocześnie ich
          bogate wzornictwo zapewnia podobne doznania estetyczne. Prócz warstwy
          imitującej drewno można także spotkać panele ze wzorem kamienia czy
          motywu płytek ceramicznych. Są dużo łatwiejsze w utrzymaniu od innych
          rodzajów podłóg gdyż do ich czyszczenia wystarcza woda z detergentem.
          Ich niewątpliwą zaletą jest fakt, iż układane mogą być na dowolnej
          powierzchni (sprzyja temu spodnia warstwa odprężająca) i użytkowane
          natychmiast po ułożeniu. Laminowana powierzchnia zapewnia dużą
          odporność na przenikanie wilgoci, przy czym należy pamiętać, że
          zazwyczaj takie panele mają laminowana zarówno spodnią jak i
          wierzchnia warstwę. Część nośna paneli zbudowana jest z płyty HDF
          (sprasowane włókna drzewne z dodatkiem związków organicznych) lub
          twardej płyty pilśniowej MDF i na nią naniesiony jest wzór imitujący
          drewno. Warstwa dekoracyjna pokryta jest termoutwardzalną żywicą,
          która czyni panel odporny na uderzenia, zarysowania, obciążenia oraz
          wysokie temperatury. Prócz odporności na wilgoć panele laminowane
          dobrze znoszą kontakt z chemikaliami używanymi na co dzień w
          gospodarstwie domowym, nie płowieją pod wpływem światła i można je
          stosować w pomieszczeniach o dużym ruchu. Co ważniejsze - to podłogi
          idealne dla alergików gdyż są higieniczne i bardzo łatwe do utrzymania
          w czystości. Instalować możemy dwa rodzaje paneli: klejone i
          bezklejowe (inaczej podłoga pływająca). Wśród paneli drewnopodobnych
          można także znaleźć panele okleinowane cienką warstwą naturalnego
          drewna.</span
        >
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Zamek magnetyczny czy standardowy?"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span
          >Od kilku lat dostępne są na naszym rynku zamki magnetyczne, i dzięki
          swym zaletom zdobywają coraz większą popularność.</span
        >
        <span>Zalety zamków magnetycznych:</span>
        <span>
          1. Design - zamki te posiadają chowający się przy otwartych drzwiach
          trzpień (tzw. "język"), a także niewidoczną od czoła ościeżnicy blachę
          zaczepową. Ma to wysokie walory estetyczne szczególnie w produkowanych
          przez nas drzwiach bezprzylgowych (bezfelcowych).
        </span>
        <span
          >2. Funkcjonalność - zamki te są zdecydowanie cichsze niż standardowe
          (mechaniczne). Działają one na zasadzie przyciągania się odmiennych
          biegunów magnesu umieszczonego w zamku (skrzydle drzwiowym) i blasze
          zaczepowej (ościeżnicy). Moment zatrzaśniecia następuje w chwili
          domknięcia się skrzydła do uszczelki drzwiowej unikając, jak w
          przypadku zamka mechanicznego, metalicznego odgłosu uderzenia "języka"
          zamka o blachę zaczepową.</span
        >
        <span>
          3. Jakość - stosowane przez nas zamki włoskiej firmy Bonaiti są
          najlepszymi mechanizmami tego typu dostępnymi na światowym rynku oraz
          posiadają stosowne atesty potwierdzające ich wysoką żywotność.
        </span>
        <span>
          <img src="assets/guide/guide-1.jpg" class="object-scale-down" />
        </span>
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Jakie klamki stosuje się w drzwiach wewnętrznych, a jakie w zewnętrznych?"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span
          >Niektórzy producenci oferują identycznie wyglądające klamki dla obu
          rodzajów drzwi. Należy jednak zwrócić uwagę na fakt, że te
          przeznaczone do drzwi zewnętrznych muszą charakteryzować się wyższą
          odpornością na działanie warunków atmosferycznych, niż stosowane w
          drzwiach wewnętrznych. Dodatkowo jej wymiary powinny być dopasowane do
          grubości skrzydła. W związku z tym, podczas kupowania klamki trzeba
          poinformować sprzedawcę o jej przyszłym miejscu użytkowania.</span
        >
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Drzwi przylgowe czy bezprzylgowe?"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span>
          Drzwi przylgowe od bezprzylgowych różnią się tym, że mają specjalne
          pionowe wycięcia na krawędziach skrzydeł, zwiększające powierzchnię
          przylegania do ościeżnicy. Widać w nich charakterystyczny uskok
          zamkniętego skrzydła i ościeżnicy. Różnicę można zobaczyć wyłącznie po
          stronie otwierania drzwi. Przy zamkniętych drzwiach oba rozwiązania
          wyglądają identycznie.
        </span>
        <span>
          Drzwi bezprzylgowe są uważane za nowocześniejsze od konstrukcji
          przylgowych. Tymczasem ich walory użytkowe niewiele się różnią od
          drzwi klasycznych. W niektórych pomieszczeniach ważny może być efekt
          dekoracyjny, jaki można dzięki nim osiągnąć.
        </span>
        <span>
          <img src="assets/guide/guide-2.jpg" />
        </span>
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Czy do każdego otworu w murze można zamontować ościeżnicę regulowaną?"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span
          >Ościeżnicę regulowaną montuje się jedynie w otworach drzwiowych, w
          których po obu stronach występują ścianki, na które można założyć
          ościeżnicę. Grubość muru musi wynosić co najmniej 75 mm.</span
        >
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Jakie są rodzaje ościeżnic?"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span
          >Są dwa rodzaje ościeżnic; stałe i regulowane. Pierwsze mają
          szerokość: 60 lub 100 mm. Regulowane posiadają listwy obejmujące,
          które pozwalają dopasować je do wymiarów muru. Są łatwiejsze w montażu
          oraz demontażu i mogą być stosowane, gdy grubość muru przekracza 75
          mm. Zakres ich regulacji wynosi zazwyczaj 20 mm. W przypadku muru o
          szerokości powyżej 300 mm stosuje się ościeżnicę złożoną z dwóch
          niezależnych elementów połączonych ze sobą w widoczny sposób.</span
        >
        <span> Ościeżnice stałe i regulowane do drzwi wewnętrznych </span>
        <span>
          <img src="assets/guide/guide-3.jpg" />
        </span>
        <span
          >Ościeżnica nakładkowa OKZ umożliwia wymianę drzwi bez potrzeby
          demontażu starej metalowej ościeżnicy. Rozwiązanie dedykowane jest dla
          osób, które nie mają możliwości demontażu starej ościeżnicy lub
          demontaż jest problematyczny np. stara futryna zalana jest betonem, a
          jej demontaż wiąże się z dużym nakładem pracy oraz możliwościami
          uszkodzenia płytek lub tynków.</span
        >
        <span>
          <img src="assets/guide/guide-4.jpg" />
        </span>
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="W jaki sposób wykończone są skrzydła wewnątrzlokalowe?"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span
          >Skrzydła wewnątrzlokalowe można lakierować lub pokrywać okleinami -
          naturalną (fornir) bądź sztuczną (laminat). Producenci oferują również
          surowe skrzydła drewniane i szklane. Najczęściej wykorzystuje się
          jednak okleiny sztuczne - CPL, drewnopodobne i Decor.</span
        >
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Co to jest płyta wiórowa otworowa?"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span>
          Płyta wiórowa otworowa (płyta kanałowa) - to wypełnienie wzmacniające
          konstrukcję skrzydeł montowanych najczęściej w pomieszczeniach
          użyteczności publicznej. Zapewnia lepszą akustyczność niż "plaster
          miodu". Dzięki wewnętrznym otworom, konstrukcja skrzydła jest lekka,
          ale zachowuje swoje właściwości; trwałość i stabilność.
        </span>
        <span>
          <img src="assets/guide/guide-5.jpg" />
        </span>
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Co to jest plaster miodu?"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span>
          Plaster miodu - to stabilizujące wypełnienie kartonowe, które
          występuje w skrzydłach o lekkiej konstrukcji, stosowanych przeważnie w
          budownictwie mieszkaniowym. W przekroju przypomina ono strukturą
          plastry miodu. Charakteryzuje się niskim współczynnikiem ocieplenia i
          słabą akustyką.
        </span>
        <span>
          <img src="assets/guide/guide-6.jpg" />
        </span>
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Wypełnienie skrzydła drzwiowego"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span
          >Wnętrze skrzydła zależy od rodzaju, funkcji i przeznaczenia drzwi.
          Wypełnienie zewnętrznych skrzydeł drewnianych stanowi najczęściej
          styropian lub specjalna płyta porowata. Skrzydła wykonane z metalu lub
          żywic poliestrowych wypełnia się pianką poliuretanową lub wełną. W
          drzwiach wewnętrznych stosuje się najczęściej - tekturowy "plaster
          miodu", płytę wiórową otworową, płytę MDF lub sklejkę drewnianą.
          Wypełnienie drzwi wpływa na jego akustykę i izolację termiczną,
          decyduje także o stabilności, trwałości i bezpieczeństwie.</span
        >
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Wysokość skrzydła drzwiowego"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span
          >Wnętrze skrzydła zależy od rodzaju, funkcji i przeznaczenia drzwi.
          Wypełnienie zewnętrznych skrzydeł drewnianych stanowi najczęściej
          styropian lub specjalna płyta porowata. Skrzydła wykonane z metalu lub
          żywic poliestrowych wypełnia się pianką poliuretanową lub wełną. W
          drzwiach wewnętrznych stosuje się najczęściej - tekturowy "plaster
          miodu", płytę wiórową otworową, płytę MDF lub sklejkę drewnianą.
          Wypełnienie drzwi wpływa na jego akustykę i izolację termiczną,
          decyduje także o stabilności, trwałości i bezpieczeństwie.</span
        >
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Wysokość skrzydła drzwiowego"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span
          >To odległość, którą mierzy się między progiem, a najwyższym punktem
          ościeżnicy.</span
        >
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Szerokość skrzydła drzwiowego"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span
          >To odległość pomiędzy najgłębszymi miejscami w belkach pionowych
          ościeżnicy.</span
        >
      </div>
    </p-accordionTab>
    <p-accordionTab
      contentStyleClass="bg-white text-black rounded-lg"
      tabStyleClass="py-2 rounded-lg"
      header="Jak określa się stronę drzwi?"
    >
      <div class="flex flex-col gap-4 font-sans text-black">
        <span
          >Kierunki skrzydeł określa się na podstawie strony, w którą otwierają
          się drzwi. Kierunek można łatwo ustalić stając po stronie zawiasów.
          Jeśli są one widoczne na belce po prawej stronie - skrzydła są prawe,
          jeśli po lewej - lewe.</span
        >
      </div>
    </p-accordionTab>
  </p-accordion>
</div>
