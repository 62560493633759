import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-showers',
  templateUrl: './showers.component.html',
  styleUrls: ['./showers.component.scss'],
  standalone: true,
  imports: [ImagePreviewComponent],
})
export class ShowersComponent {
  numbers = Array.from({ length: 28 }, (_, i) => i + 1);

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle(
      'Ścianki Prysznicowe - Kabiny Prysznicowe | KamaDOOR'
    );
  }
}
