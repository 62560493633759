<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Drzwi wewnętrzne na starą metalową ościeżnicę
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span>
      Jeśli poszukują Państwo rozwiązania, dzięki któremu wymienią drzwi bez
      konieczności demontażu starych metalowych futryn, zapraszamy!
    </span>
    <span
      >Mamy dla Państwa innowacyjną usługę -
      <b
        >montaż drzwi z ościeżnicą nakładkową, którą montuje się na stare
        futryny.</b
      >
    </span>
    <span
      >Ościeżnica nakładkowa obejmuje zamontowaną wcześniej ościeżnicę metalową,
      dzięki temu można zaaranżować wnętrze od "nowa" i nadać mu niepowtarzalny
      charakter bez przeprowadzania skomplikowanych prac budowlanych.
    </span>
    <span class="mt-6"
      >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
      pisemnego zapytania przy użyciu
      <a
        href="kontakt/formularz-kontaktowy"
        class="text-kamadoor-orange hover:underline"
        >FORMULARZA KONTAKTOWEGO.</a
      ></span
    >
  </div>

  <div class="mt-10 flex flex-col w-full">
    <span class="text-white font-semibold pb-2"
      >Poniżej przedstawiamy przykładowe modele drzwi, które można zamontować z
      ościeżnicą nakładkową na stare futryny:</span
    >
  </div>

  <div
    class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 desktopSmall:grid-cols-4 justify-between gap-y-8"
  >
    @for (number of numbers; track number) {
    <kamadoor-image-preview
      [src]="
        'assets/offer/internal/old-frame/DN-' +
        number.toString().padStart(2, '0') +
        '.jpg'
      "
      [label]="'DN-' + number.toString().padStart(2, '0')"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    }
  </div>
</div>
