import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-dre-uni',
  templateUrl: './uni.component.html',
  styleUrls: ['./uni.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class DreUniComponent {
  items = [
    {
      label: 'Bulaj MDF',
      image: 'mdf.jpg',
    },
    {
      label: 'Bulaj metalowy',
      image: 'metal.jpg',
    },
    {
      label: 'Uni 10',
      image: 'uni-10.jpg',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi wewnętrzne DRE Uni | KamaDOOR');
  }
}
