<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Drzwi ukryte
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span>
      Drzwi z ukrytą ościeżnicą lub inaczej „drzwi niewidoczne„ są innowacyjnym
      rozwiązaniem, pozwalającym na projektowanie wnętrz z wykorzystaniem drzwi
      wbudowanych w ścianę. Nowoczesna w całości wykonana z aluminium
      ościeżnica, lakierowana przy użyciu technologii proszkowej w kolorze
      białym, zostaje w całości w sposób niewidoczny, osadzona we wnęce
      drzwiowej.
    </span>

    <span
      >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
      pisemnego zapytania przy użyciu
      <a
        href="kontakt/formularz-kontaktowy"
        class="text-kamadoor-orange hover:underline"
        >FORMULARZA KONTAKTOWEGO.</a
      ></span
    >

    <div
      class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 desktopSmall:grid-cols-4 justify-between gap-y-8"
    >
      @for (number of numbers; track number) {
      <kamadoor-image-preview
        [src]="'assets/offer/hidden/' + number.toString() + '.jpg'"
        labelClass="pt-4"
      ></kamadoor-image-preview>
      }
    </div>

    <span>
      Konstrukcja skrzydła drzwi ukrytych, wykonana jest na bazie ramy nośnej z
      drewna iglastego. Wypełnienie wewnętrzne stanowi sprasowana płyta pełna o
      wysokich właściwościach akustycznych. Całość skrzydła drzwiowego jest
      obustronnie oklejona płytą HDF o grubości 5 mm. Wykończenie zewnętrze,
      może zostać wykonane w formie naturalnej okleiny – forniru lub pokryte
      lakierem kryjącym w kolorze białym oraz popielatym. Wykończone są także
      wszystkie krawędzie wraz z krawędzią spodnią.
    </span>

    <p-image
      src="assets/offer/hidden/base.jpg"
      alt="Image"
      [preview]="true"
      imageClass="max-w-[300px] desktop:max-w-[500px] p-6"
    />
  </div>
</div>
