<div
  [class]="
    'h-[' +
    maxH +
    'px] flex flex-col text-center border-t-2 pt-8 border-t-white'
  "
  [ngClass]="{ 'border-t-2 border-t-white': showBorder }"
>
  <p-image
    class="h-[90%]"
    [src]="src"
    [preview]="true"
    styleClass="h-full"
    imageClass="object-contain h-full"
  />
  @if (label) {
  <span [class]="labelClass">{{ label }}</span>
  }
</div>
