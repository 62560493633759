import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-dre-vetro-e',
  templateUrl: './vetro-e.component.html',
  styleUrls: ['./vetro-e.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class DreVetroEComponent {
  items = [
    {
      label: 'Vetro E - biały',
      image: 'white.jpg',
    },
    {
      label: 'Vetro E - dąb halifax naturalny',
      image: 'halifax-natural.jpg',
    },
    {
      label: 'Vetro E - dąb halifax szary',
      image: 'halifax-gray.jpg',
    },
    {
      label: 'Vetro E - dąb halifax tabac',
      image: 'halifax-tabac.jpg',
    },
    {
      label: 'Vetro E - ecru',
      image: 'ecru.jpg',
    },
    {
      label: 'Vetro E - latte',
      image: 'latte.jpg',
    },
    {
      label: 'Vetro E - naturalny',
      image: 'natural.jpg',
    },
    {
      label: 'Vetro E - popielaty',
      image: 'ashen.jpg',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi wewnętrzne DRE Vetro-E | KamaDOOR');
  }
}
