import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { DataViewModule } from 'primeng/dataview';

import { OfferButtonComponent } from '@components/offer-button/offer-button.component';

@Component({
  selector: 'kamadoor-internal-dre',
  templateUrl: './dre.component.html',
  styleUrls: ['./dre.component.scss'],
  imports: [DataViewModule, OfferButtonComponent],
  standalone: true,
})
export class InternalDreComponent {
  offerItems = [
    {
      name: 'DRE Vetro E',
      image: 'vetro-e.jpg',
      url: 'drzwi-wewnetrzne-dre-vetro-e',
    },
    {
      name: 'DRE Uni',
      image: 'uni.jpg',
      url: 'drzwi-wewnetrzne-dre-uni',
    },
    {
      name: 'DRE Wood',
      image: 'wood.jpg',
      url: 'drzwi-wewnetrzne-dre-wood',
    },
    {
      name: 'DRE Nestor',
      image: 'nestor.jpg',
      url: 'drzwi-wewnetrzne-dre-nestor',
    },
    {
      name: 'DRE Auri',
      image: 'auri.jpg',
      url: 'drzwi-wewnetrzne-dre-auri',
    },
    {
      name: 'DRE Standard',
      image: 'standard.jpg',
      url: 'drzwi-wewnetrzne-dre-standard',
    },
    {
      name: 'DRE Berge',
      image: 'berge.jpg',
      url: 'drzwi-wewnetrzne-dre-berge',
    },
    {
      name: 'DRE Vetro D',
      image: 'vetro-d.jpg',
      url: 'drzwi-wewnetrzne-dre-vetro-d',
    },
    {
      name: 'DRE Połyskowe',
      image: 'shiny.jpg',
      url: 'drzwi-wewnetrzne-dre-polyskowe',
    },
    {
      name: 'DRE Nova',
      image: 'nova.jpg',
      url: 'drzwi-wewnetrzne-dre-nova',
    },
    {
      name: 'DRE Binito',
      image: 'binito.jpg',
      url: 'drzwi-wewnetrzne-dre-binito',
    },
    {
      name: 'DRE Scala',
      image: 'scala.jpg',
      url: 'drzwi-wewnetrzne-dre-scala',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi wewnętrzne DRE | KamaDOOR');
  }
}
