import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-sliding-folding',
  templateUrl: './folding.component.html',
  styleUrls: ['./folding.component.scss'],
  standalone: true,
  imports: [ImagePreviewComponent],
})
export class SlidingFoldingComponent {
  numbers = Array.from({ length: 4 }, (_, i) => i + 1);

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi łamane - Drzwi składane | KamaDOOR');
  }
}
