<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Drzwi przesuwne drewniane
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span>
      Wykonujemy nietypowe zabudowy drzwiowe w postaci drewnianych drzwi
      przesuwnych, które są produkowane na indywidualne zamówienie.
    </span>
    <span>
      Po dokonaniu pomiaru otworu drzwiowego określamy wraz z klientem
      indywidualne cechy techniczne i wizualne drzwi w taki sposób, aby produkt
      końcowy, spełnił oczekiwania zamawiającego.
    </span>
    <span
      >Poniżej prezentujemy przykładowe zdjęcia drewnianych drzwi przesuwnych,
      dostarczonych klientom.</span
    >
    <span
      >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
      pisemnego zapytania przy użyciu
      <a
        href="kontakt/formularz-kontaktowy"
        class="text-kamadoor-orange hover:underline"
        >FORMULARZA KONTAKTOWEGO.</a
      ></span
    >
    <span
      >Prosimy w Formularzu wypełnić wszystkie wymagane pola oraz wskazać nam
      numer zdjęcia z poniższych lub przesłać własne zdjęcie obrazujące drzwi,
      jakie mają zostać wycenione oraz podać orientacyjny wymiar otworu
      drzwiowego – jego szerokość oraz wysokość.</span
    >

    <div
      class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 desktopSmall:grid-cols-4 justify-between gap-y-8"
    >
      @for (number of numbers; track number) {
      <kamadoor-image-preview
        [src]="'assets/offer/sliding/wooden/' + number.toString() + '.jpg'"
        [label]="'DPD-' + number.toString().padStart(2, '0')"
        labelClass="pt-4"
      ></kamadoor-image-preview>
      }
    </div>
  </div>
</div>
