<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    KONTAKT
  </span>

  <div class="hidden desktopSmall:flex flex-col w-full">
    <div class="flex flex-row gap-x-2 mt-6 items-center">
      <div class="flex w-1/2">
        <img class="w-full object-fill" src="assets/contact/contact-1.jpg" />
      </div>
      <div class="flex w-1/2">
        <kamadoor-contact-form class="w-full"></kamadoor-contact-form>
      </div>
    </div>
    <div class="flex flex-row gap-x-2 items-center">
      <div class="flex flex-col w-1/2 gap-4 text-white text-xl justify-center">
        <span class="flex gap-4 ml-4 items-center w-full">
          <i class="fa-solid fa-map-location-dot"></i>
          Poznań, ul. Zdziechowska 1 - wejście od ul. Palacza (obok budynku
          Lotto)
        </span>
        <span class="flex gap-4 ml-4 items-center w-full">
          <i class="fa-solid fa-at"></i>
          <a
            class="hover:text-kamadoor-orange hover:underline"
            href="mailto:sklep&#64;kamadoor.pl"
            >sklep&#64;kamadoor.pl</a
          >
        </span>
        <span class="flex gap-4 ml-4 items-center w-full">
          <i class="fa-solid fa-phone"></i>
          <a
            class="hover:text-kamadoor-orange hover:underline"
            href="tel:+48606675923"
            >606 675 923</a
          >
        </span>
        <span class="flex gap-4 ml-4 items-center w-full">
          <i class="fa-solid fa-phone"></i>
          <a
            class="hover:text-kamadoor-orange hover:underline"
            href="tel:+48662081045"
            >662 081 045</a
          >
        </span>
        <span class="flex gap-4 ml-4 items-center w-full">
          <i class="fa-solid fa-door-open"></i>
          <span> Poniedziałek - Piątek 09:00 - 17:00 </span>
        </span>
      </div>
      <iframe
        class="w-1/2 h-[450px] border-none"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2434.9131349977815!2d16.879286!3d52.390125!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4704452641e734c1%3A0xa7c3aa0a655d39a8!2sKamadoor!5e0!3m2!1spl!2spl!4v1414582068149"
        frameborder="0"
      ></iframe>
    </div>
  </div>

  <div class="flex desktopSmall:hidden flex-col w-full mt-6 gap-2">
    <div class="flex flex-col w-full gap-4 text-white text-xl justify-center">
      <span class="flex gap-4 ml-4 items-center w-full">
        <i class="fa-solid fa-map-location-dot"></i>
        Poznań, ul. Zdziechowska 1 - wejście od ul. Palacza (obok budynku Lotto)
      </span>
      <span class="flex gap-4 ml-4 items-center w-full">
        <i class="fa-solid fa-at"></i>
        <a
          class="hover:text-kamadoor-orange hover:underline"
          href="mailto:sklep&#64;kamadoor.pl"
          >sklep&#64;kamadoor.pl</a
        >
      </span>
      <span class="flex gap-4 ml-4 items-center w-full">
        <i class="fa-solid fa-phone"></i>
        <a
          class="hover:text-kamadoor-orange hover:underline"
          href="tel:+48606675923"
          >606 675 923</a
        >
      </span>
      <span class="flex gap-4 ml-4 items-center w-full">
        <i class="fa-solid fa-phone"></i>
        <a
          class="hover:text-kamadoor-orange hover:underline"
          href="tel:+48662081045"
          >662 081 045</a
        >
      </span>
      <span class="flex gap-4 ml-4 items-center w-full">
        <i class="fa-solid fa-door-open"></i>
        <span> Poniedziałek - Piątek 09:00 - 17:00 </span>
      </span>
    </div>
    <kamadoor-contact-form class="w-full"></kamadoor-contact-form>
    <img class="w-full object-fill" src="assets/contact/contact-1.jpg" />
    <iframe
      class="w-full h-[450px] border-none"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2434.9131349977815!2d16.879286!3d52.390125!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4704452641e734c1%3A0xa7c3aa0a655d39a8!2sKamadoor!5e0!3m2!1spl!2spl!4v1414582068149"
      frameborder="0"
    ></iframe>
  </div>
</div>
