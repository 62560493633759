<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Drzwi łamane - Drzwi składane
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span> Drzwi Łamane, Składane, Harmonijkowe. </span>
    <span>
      Jesteśmy specjalistyczną firmą, wykonującą na indywidualne zamówienie
      Szklane Drzwi Łamane .
    </span>
    <span
      >Tego typu rozwiązanie konstrukcyjne, jest coraz częściej poszukiwanym
      przez klientów, ze względu na swe unikatowe walory użytkowe.</span
    >
    <span
      >Drzwi Składane, nadają się do zastosowania zarówno w pomieszczeniach o
      niewielkich metrażach, jak również wszędzie tam, gdzie mamy do
      przesłonięcia szerokie przestrzenie.</span
    >
    <span
      >Drzwi Łamane, nazywane również Harmonijkowymi, umożliwiają ekonomiczne
      zarządzanie dostępną powierzchnią po ich otwarciu, ponieważ wszystkie
      zespolone ze sobą tafle szkła, można pomieścić na wąskiej przestrzeni przy
      ścianie.</span
    >
    <span>
      Produkowane przez naszą firmę drzwi Łamane, umożliwiają przesłanianie
      powierzchni o wymiarach:
      <ul class="list-disc ml-10 mt-4 flex flex-col gap-4">
        <li>wysokość całkowita: do 2,5 - 2,6m,</li>
        <li>
          szerokości dla jednego skrzydła po otwarciu : od 0,9m do 5,0m
          długości,
        </li>
        <li>szerokość dla dwóch skrzydeł po otwarciu: do 10m długości.</li>
      </ul>
    </span>
    <span
      >Poniżej prezentujemy przykładowe zdjęcia szklanych drzwi Łamanych,
      wykonanych przez naszą firmę.</span
    >
    <span
      >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
      pisemnego zapytania przy użyciu
      <a
        href="kontakt/formularz-kontaktowy"
        class="text-kamadoor-orange hover:underline"
        >FORMULARZA KONTAKTOWEGO.</a
      ></span
    >
    <span
      >Prosimy w Formularzu wypełnić wszystkie wymagane pola, oraz podać
      orientacyjny wymiar otworu drzwiowego – jego szerokość oraz
      wysokość.</span
    >

    <div
      class="grid grid-flow-row grid-cols-1 desktopSmall:grid-cols-4 justify-between gap-y-8"
    >
      @for (number of numbers; track number) {
      <kamadoor-image-preview
        [src]="'assets/offer/sliding/folding/' + number.toString() + '.jpg'"
        [label]="'DŁ-DS-' + number.toString().padStart(2, '0')"
        labelClass="pt-4"
      ></kamadoor-image-preview>
      }
    </div>

    <div
      class="grid grid-flow-row grid-cols-1 desktopSmall:grid-cols-3 justify-between gap-y-8 mt-10"
    >
      <kamadoor-image-preview
        src="
          assets/offer/sliding/folding/5.jpg
        "
        label="DŁ-DS-05"
        labelClass="pt-4"
      ></kamadoor-image-preview>
      <kamadoor-image-preview
        src="
          assets/offer/sliding/folding/6.jpg
        "
        label="DŁ-DS-06"
        labelClass="pt-4"
      ></kamadoor-image-preview>
      <kamadoor-image-preview
        src="
          assets/offer/sliding/folding/7.jpg
        "
        label="DŁ-DS-07"
        labelClass="pt-4"
      ></kamadoor-image-preview>
    </div>
  </div>
</div>
