<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans text-4xl text-white"
  >
    Drzwi zewnętrzne
  </span>

  <div class="flex flex-col gap-4 pt-8 font-sans mb-8 text-white">
    <span
      >Oferujemy drzwi, spełniające wymogi ogólnopolskiego programu:
      <b>Czyste Powietrze – Zdrowy Wybór.</b></span
    >
    <span
      >Program obejmuje zakup i montaż ocieplenia przegród budowlanych, okien,
      drzwi zewnętrznych, drzwi/bram garażowych (zawiera również demontaż).
    </span>
    <span
      >W ramach programu drzwi zewnętrzne powinny charakteryzować się
      przenikalnością cieplną
      <span class="font-semibold underline">Ucmax - 1,3 W/m2*K.</span></span
    >
    <span class="flex flex-col">
      Nasza firma oferuje certyfikowane drzwi zewnętrzne, posiadające
      przenikalność cieplną:
      <ul class="list-disc ml-10">
        <li>płytowe pełne - <b>Uc = 0,8 W/m2*K</b></li>
        <li>płytowe oszklone - <b>Uc = 0,84 W/m2*K</b></li>
        <li>ramiakowe pełne - <b>Uc = 1,1 W/m2*K</b></li>
        <li>ramiakowe oszklone - <b>Uc = 1,2 W/m2*K</b></li>
      </ul>
    </span>

    <span
      >W przypadku zainteresowania, prosimy o skontaktowanie się z
      przedstawicielami naszej firmy, celem pozyskania szczegółowych informacji,
      dotyczących asortymentu spełniającego powyżej przedstawione wymogi.</span
    >
  </div>

  <p-dataView [value]="offerItems" layout="grid">
    <ng-template let-product pTemplate="grid" let-items>
      <div
        class="grid grid-flow-row justify-items-center grid-cols-1 mobileLarge:grid-cols-2 desktopSmall:grid-cols-3 desktop:grid-cols-4 justify-between gap-y-4 bg-kamadoor-light-gray"
      >
        @for (item of items; track item.name) {
        <kamadoor-offer-button
          [label]="item.name"
          [src]="'assets/offer/external/' + item.image"
          [url]="item.url"
        ></kamadoor-offer-button>
        }
      </div>
    </ng-template>
  </p-dataView>
</div>
