<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Drzwi wejściowe do mieszkania BARAŃSKI
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span>
      Drzwi wejściowe do mieszkania Barański SMART cechują wyjątkowe parametry w
      zakresie antywłamaniowości, akustyki oraz przeciwpożarowości. Zapewniają
      bezpieczeństwo przed włamaniami, jednocześnie chronią przed hałasem oraz
      zapobiegają rozprzestrzenianiu się ognia w przypadku pożaru. W
      mieszkaniach idealnie sprawdzają się jako drzwi klatkowe, a także są
      wykorzystywane w prywatnych domach do oddzielenia pomieszczeń
      gospodarczych od części mieszkalnej. Drzwi techniczne Barański Smart to
      także idealne rozwiązanie dla hoteli, inwestycji i budynków użyteczności
      publicznej.
    </span>
    <span
      >Pełna oferta drzwi jest dostępna w
      <a
        class="text-kamadoor-orange hover:underline"
        href="https://www.baranskidrzwi.pl/katalogi/"
        >katalogu.</a
      >
    </span>

    <span
      >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
      pisemnego zapytania przy użyciu
      <a
        href="kontakt/formularz-kontaktowy"
        class="text-kamadoor-orange hover:underline"
        >FORMULARZA KONTAKTOWEGO.</a
      ></span
    >
  </div>

  <div class="mt-10 flex flex-col w-full">
    <span class="text-white font-semibold pb-2"
      >Poniżej przedstawiamy wybrane modele tego producenta:</span
    >
  </div>

  <div
    class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 desktopSmall:grid-cols-4 justify-between gap-y-8"
  >
    @for (number of numbers; track number) {
    <kamadoor-image-preview
      [src]="
        'assets/offer/apartment-internal/baranski/DWMB-' +
        number.toString().padStart(2, '0') +
        '.JPG'
      "
      [label]="'DW-MB-' + number.toString().padStart(2, '0')"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    }
  </div>
</div>
