<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 mb-6 w-full font-sans text-4xl text-white border-b-2 border-b-kamadoor-orange"
  >
    DRE Wood
  </span>

  <span class="mb-6"
    >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
    pisemnego zapytania przy użyciu
    <a
      href="kontakt/formularz-kontaktowy"
      class="text-kamadoor-orange hover:underline"
      >FORMULARZA KONTAKTOWEGO.</a
    ></span
  >

  <div
    class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 desktopSmall:grid-cols-4 justify-between gap-y-8"
  >
    @for (item of items; track item.label) {
    <kamadoor-image-preview
      [src]="'assets/offer/internal/dre/wood/' + item.image"
      [label]="item.label"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    }
  </div>
  <div class="grid grid-flow-row grid-cols-1 justify-between gap-y-8 mt-10">
    <kamadoor-image-preview
      src="assets/offer/internal/dre/wood/m4w1.jpg"
      label="Wood M4 - W1"
      labelClass="pt-4"
    ></kamadoor-image-preview>
  </div>
</div>
