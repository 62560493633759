<p-toast
  class="inline desktopSmall:hidden"
  position="top-center"
  styleClass="w-[90%]"
></p-toast>
<p-toast class="hidden desktopSmall:inline"></p-toast>

<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Formularz kontaktowy
  </span>

  <form class="mt-5 flex flex-col gap-5" [formGroup]="formGroup">
    <div class="flex flex-col gap-2">
      <label class="font-semibold">Firma / osoba kontaktowa *</label>
      <input type="text" pInputText formControlName="client" />
    </div>

    <div class="flex flex-col gap-2">
      <label class="font-semibold">Miasto *</label>
      <input type="text" pInputText formControlName="city" />
    </div>

    <div class="flex flex-col gap-2">
      <label class="font-semibold">Telefon kontaktowy *</label>
      <input type="text" pInputText formControlName="phoneNumber" />
    </div>

    <div class="flex flex-col gap-2">
      <label class="font-semibold">E-mail *</label>
      <input type="text" pInputText formControlName="email" />
    </div>

    <div class="flex flex-col gap-2">
      <label class="font-semibold">Zapytanie *</label>
      <textarea rows="3" cols="30" pInputTextarea formControlName="question">
      </textarea>
    </div>

    <p-button
      label="Wyślij zapytanie"
      [disabled]="!formGroup.valid || buttonDisabled"
      (onClick)="sendMail()"
    ></p-button>
  </form>
</div>
