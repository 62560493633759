<div class="pt-4 pb-8 pl-2 border-t border-t-white flex gap-2">
  <div>
    <span>© 2012 Kama</span>
    <span class="text-kamadoor-orange">Door</span>
  </div>
  <span>|</span>
  <a
    class="text-kamadoor-orange hover:text-kamadoor-dark-orange hover:underline"
    href="polityka-prywatnosci"
    >Polityka Prywatności</a
  >
  <span>|</span>
  <a
    class="text-kamadoor-orange hover:text-kamadoor-dark-orange hover:underline"
    href="klauzula-informacyjna-rodo"
    >Klauzula informacyjna RODO</a
  >
</div>
