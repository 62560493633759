import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-dre-scala',
  templateUrl: './scala.component.html',
  styleUrls: ['./scala.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class DreScalaComponent {
  items = [
    {
      label: 'Scala A',
      image: 'a.jpg',
    },
    {
      label: 'Scala A1',
      image: 'a1.jpg',
    },
    {
      label: 'Scala A2',
      image: 'a2.jpg',
    },
    {
      label: 'Scala A3',
      image: 'a3.jpg',
    },
    {
      label: 'Scala A4',
      image: 'a4.jpg',
    },
    {
      label: 'Scala B5',
      image: 'b5.jpg',
    },
    {
      label: 'Scala B6',
      image: 'b6.jpg',
    },
    {
      label: 'Scala B7',
      image: 'b7.jpg',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi wewnętrzne DRE Scala | KamaDOOR');
  }
}
