import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { DataViewModule } from 'primeng/dataview';

import { OfferButtonComponent } from '@components/offer-button/offer-button.component';

@Component({
  selector: 'kamadoor-accessories',
  templateUrl: './accessories.component.html',
  styleUrls: ['./accessories.component.scss'],
  imports: [DataViewModule, OfferButtonComponent],
  standalone: true,
})
export class AccessoriesComponent {
  offerItems = [
    {
      name: 'LISTWY PRZYPODŁOGOWE',
      image: 'board.jpg',
      url: 'listwy-przypodlogowe',
    },
    {
      name: 'KLAMKI DO DRZWI',
      image: 'handle.jpg',
      url: 'klamki-do-drzwi',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle(
      'Listwy przypodłogowe / Klamki do drzwi | KamaDOOR'
    );
  }
}
