<p-carousel
  [value]="images"
  [numVisible]="1"
  [numScroll]="1"
  [circular]="true"
  [showNavigators]="true"
  [showIndicators]="false"
>
  <ng-template let-item pTemplate="item">
    <div
      class="bg-black min-h-full min-w-full flex flex-col justify-center items-center"
    >
      <p-image
        appendTo="body"
        [src]="item.itemImageSrc"
        [preview]="true"
        imageClass="w-[200px] h-[300px] desktop:w-[500px] desktop:h-[650px] object-contain block"
      />
      <span
        class="w-full my-2 h-5 bg-black text-xl text-center font-semibold leading-5"
        >{{ item.title }}</span
      >
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <small class="my-2 flex desktop:hidden w-full justify-center"
      >Przeciągnij w lewo/prawo, aby przeglądać</small
    >
  </ng-template>
</p-carousel>
