import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-dre-vetro-d',
  templateUrl: './vetro-d.component.html',
  styleUrls: ['./vetro-d.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class DreVetroDComponent {
  items = [
    {
      label: 'Vetro D1 szyba decormat brąz',
      image: 'brown.jpg',
    },
    {
      label: 'Vetro D1 szyba decormat grafit',
      image: 'graphite.jpg',
    },
    {
      label: 'Vetro D1 szyba decormat',
      image: 'decormat.jpg',
    },
    {
      label: 'Vetro D1 szyba przezroczysta',
      image: 'transparent.jpg',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi wewnętrzne DRE Vetro-D | KamaDOOR');
  }
}
