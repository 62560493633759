import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImageModule } from 'primeng/image';

@Component({
  selector: 'kamadoor-apartment-internal-fireproof',
  templateUrl: './fireproof.component.html',
  styleUrls: ['./fireproof.component.scss'],
  imports: [ImageModule],
  standalone: true,
})
export class ApartmentInternalFireproofComponent {
  constructor(private readonly titleService: Title) {
    this.titleService.setTitle(
      'Drzwi wejściowe do mieszkania przeciwpożarowe | KamaDOOR'
    );
  }
}
