import { Component } from '@angular/core';

import { CarouselModule, Carousel } from 'primeng/carousel';
import { DataViewModule } from 'primeng/dataview';

import { MenuOfferButtonComponent } from '@components/menu-offer-button/menu-offer-button.component';

@Component({
  selector: 'kamadoor-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  imports: [CarouselModule, DataViewModule, MenuOfferButtonComponent],
  standalone: true,
})
export class MainPageComponent {
  carouselImages = [
    {
      image: 'logo.jpg',
    },
    {
      image: 'apartmentInternal1.jpg',
      name: 'Drzwi wewnętrzne do mieszkania',
    },
    {
      image: 'folding1.jpg',
      name: 'Drzwi przesuwne łamane',
    },
    {
      image: 'internal1.webp',
      name: 'Drzwi wewnętrzne',
    },
    {
      image: 'loft1.jpg',
      name: 'Drzwi loftowe',
    },
    {
      image: 'externalSteel.jpg',
      name: 'Drzwi zewnętrzne stalowe',
    },
    {
      image: 'internal2.webp',
      name: 'Drzwi wewnętrzne',
    },
    {
      image: 'loft2.jpg',
      name: 'Drzwi loftowe',
    },
    {
      image: 'folding2.jpg',
      name: 'Drzwi przesuwne łamane',
    },
    {
      image: 'apartmentInternal2.jpg',
      name: 'Drzwi wewnętrzne do mieszkania',
    },
    {
      image: 'internal3.webp',
      name: 'Drzwi wewnętrzne',
    },
    {
      image: 'loft3.jpg',
      name: 'Drzwi loftowe',
    },
    {
      image: 'internal4.webp',
      name: 'Drzwi wewnętrzne',
    },
    {
      image: 'internal5.jpg',
      name: 'Drzwi wewnętrzne',
    },
    {
      image: 'hidden1.jpg',
      name: 'Drzwi ukryte',
    },
    {
      image: 'internal6.webp',
      name: 'Drzwi wewnętrzne',
    },
    {
      image: 'folding3.jpg',
      name: 'Drzwi przesuwne łamane',
    },
    {
      image: 'loft4.jpg',
      name: 'Drzwi loftowe',
    },
    {
      image: 'internal7.jpg',
      name: 'Drzwi wewnętrzne',
    },
  ];

  offerItems = [
    {
      name: 'DRZWI\nZEWNĘTRZNE',
      description: 'stylowe i nowoczesne',
      image: 'external.JPG',
      url: 'drzwi-zewnetrzne',
    },
    {
      name: 'DRZWI WEJŚCIOWE\nDO MIESZKANIA',
      description: 'stylowe i nowoczesne',
      image: 'apartmentInternal.jpg',
      url: 'drzwi-wejsciowe-do-mieszkania',
    },
    {
      name: 'DRZWI\nWEWNĘTRZNE',
      description: 'fornirowane, malowane i\nlaminowane',
      image: 'internal.JPG',
      url: 'drzwi-wewnetrzne',
    },
    {
      name: 'DRZWI\nUKRYTE',
      description: 'drzwi ukryte, drzwi niewidoczne',
      image: 'hidden.jpg',
      url: 'drzwi-ukryte',
    },
    {
      name: 'DRZWI\nPRZESUWNE',
      description: 'drewniane i szklane',
      image: 'sliding.jpg',
      url: 'drzwi-przesuwne',
    },
    {
      name: 'DRZWI LOFTOWE\nDRZWI SZKLANE',
      description: 'stylowe i nowoczesne',
      image: 'loft.jpg',
      url: 'drzwi-loftowe-drzwi-szklane',
    },
    {
      name: 'ŚCIANKI PRYSZNICOWE\nKABINY PRYSZNICOWE',
      description: 'ścianki prysznicowe',
      image: 'showers.jpg',
      url: 'scianki-prysznicowe-kabiny-prysznicowe',
    },
    {
      name: 'DRZWI DO KAMIENICY\nDRZWI ZABYTKOWE',
      description: '',
      image: 'tenement.JPG',
      url: 'drzwi-do-kamienicy-drzwi-zabytkowe',
    },
  ];

  constructor() {
    Carousel.prototype.onTouchMove = () => {};
  }
}
