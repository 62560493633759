<div class="bg-kamadoor-light-gray p-4 flex flex-col gap-6 justify-center">
  <p-carousel
    class="hidden desktopSmall:flex"
    [value]="carouselImages"
    [numVisible]="1"
    [numScroll]="1"
    [circular]="true"
    [showNavigators]="false"
    autoplayInterval="10000"
    indicatorStyleClass="border-2 rounded-full h-4 w-4 border-white"
    indicatorsContentClass="w-full bg-black"
  >
    <ng-template let-item pTemplate="item">
      <div
        class="bg-black min-h-full min-w-full flex justify-center items-center relative"
      >
        <img
          src="assets/slider/{{ item.image }}"
          [alt]="item.name"
          class="object-fill max-h-[500px]"
        />
        @if (item.name) {
        <span
          class="absolute bottom-0 w-full h-10 bg-black text-xl text-center font-semibold leading-10"
          >{{ item.name }}</span
        >
        }
      </div>
    </ng-template>
  </p-carousel>

  <p-dataView [value]="offerItems" layout="grid">
    <ng-template let-product pTemplate="grid" let-items>
      <div
        class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 desktopMenuSmall:grid-cols-3 desktopMenuMedium:grid-cols-4 justify-between justify-items-center gap-y-8 bg-kamadoor-orange p-10"
      >
        @for (item of items; track item.name) {
        <kamadoor-menu-offer-button
          [label]="item.name"
          [src]="'assets/main-page-offer/' + item.image"
          [description]="item.description"
          [url]="item.url"
        ></kamadoor-menu-offer-button>
        }
      </div>
    </ng-template>
  </p-dataView>

  <span
    class="text-2xl tablet:text-3xl desktopMenuSmall:text-4xl text-white text-center"
    >KamaDOOR - DLACZEGO MY?</span
  >
  <div
    class="mx-2 grid grid-flow-row gap-x-8 gap-y-2 grid-cols-1 tablet:grid-cols-2 desktopMenuSmall:grid-cols-4 text-white"
  >
    <span
      >KamaDOOR to nowoczesny Salon Wystawowy, mieszczący się przy ul.Palacza w
      Poznaniu i posiadający w swej ofercie szeroką gamę produktów, takich jak
      Drzwi Wewnętrzne, Zewnętrzne oraz Panele Podłogowe firmy Parador.</span
    >
    <span
      >Oferowane przez naszą firmę drzwi, występują w różnych wzorach,
      rozmiarach i kolorach a także okleinach naturalnych – fornirach. Drzwi
      zewnętrzne zarówno drewniane jak i metalowe do budynków mieszkalnych oraz
      użyteczności publicznej.</span
    >
    <span
      >Naszą specjalności są wykonywanie na indywidualne zamówienie klienta
      Drzwi Szklane, zabudowy wewnętrzne oraz Drzwi Przesuwne i Drzwi
      Loftowe.</span
    >
    <span
      >Atutem naszej firmy jest duże doświadczenie w branży stolarki drzwiowej,
      które pozwala w sposób kompetentny doradzić i pomóc klientom w podjęciu
      możliwie najkorzystniejszej decyzji w zakresie zakupów obejmujących swym
      zakresem naszą ofertę produktową.</span
    >
  </div>
</div>
