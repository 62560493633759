import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-dre-nova',
  templateUrl: './nova.component.html',
  styleUrls: ['./nova.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class DreNovaComponent {
  items = [
    {
      label: 'Nova 10',
      image: '10.jpg',
    },
    {
      label: 'Nova 20',
      image: '20.jpg',
    },
    {
      label: 'Nova 30',
      image: '30.jpg',
    },
    {
      label: 'Nova 40',
      image: '40.jpg',
    },
    {
      label: 'Nova 10 beton oxide',
      image: '10-oxide.jpg',
    },
    {
      label: 'Nova 20 beton oxide',
      image: '20-oxide.jpg',
    },
    {
      label: 'Nova 30 miedź antyczna',
      image: '30-copper.jpg',
    },
    {
      label: 'Nova 40 miedź antyczna',
      image: '40-copper.jpg',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi wewnętrzne DRE Nova | KamaDOOR');
  }
}
