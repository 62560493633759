<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Realizacje
  </span>

  <div class="mt-8 grid grid-flow-row grid-cols-1 justify-between gap-8">
    @for(item of items; track item.label) {
    <div class="flex flex-col w-full items-center">
      <span
        class="inline-flex pb-2 w-full border-b-2 border-b-white font-sans text-3xl text-white items-end"
      >
        {{ item.label }}
      </span>

      <kamadoor-gallery
        class="w-[300px] desktop:w-[650px]"
        [images]="item.items"
      ></kamadoor-gallery>
    </div>
    }
  </div>
</div>
