import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '../../components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class AboutUsComponent {
  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('O nas | KamaDOOR');
  }
}
