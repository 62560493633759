<div class="mx-[5%] flex justify-between">
  <a href="/"><img class="w-full" src="assets/kamadoor.jpg" /></a>
  <a
    class="flex desktopSmall:hidden items-center"
    href="https://www.facebook.com/kamadoor.poznan"
  >
    <i
      class="text-2xl fa-brands fa-square-facebook hover:text-kamadoor-orange active:text-kamadoor-orange"
    ></i>
  </a>
  <div class="hidden desktopSmall:flex justify-between gap-4 my-2">
    <div class="flex flex-col">
      <a
        class="flex items-center gap-2 h-full hover:text-kamadoor-orange active:text-kamadoor-orange"
        href="tel:+48606675923"
      >
        <i class="text-xl fa fa-phone-square"></i>
        <span class="text-right text-xl font-extrabold"> 606 675 923 </span>
      </a>
      <a
        class="flex items-center gap-2 h-full hover:text-kamadoor-orange active:text-kamadoor-orange"
        href="https://www.facebook.com/kamadoor.poznan"
      >
        <i class="text-xl fa-brands fa-square-facebook"></i>
        <span class="text-right text-xl font-extrabold"> KamaDOOR </span>
      </a>
    </div>
    <div class="flex flex-col">
      <a
        class="flex items-center gap-2 h-full hover:text-kamadoor-orange active:text-kamadoor-orange"
        href="tel:+48662081045"
      >
        <i class="text-xl fa fa-phone-square"></i>
        <span class="text-right text-xl font-extrabold"> 662 081 045 </span>
      </a>

      <a
        class="flex items-center gap-2 h-full hover:text-kamadoor-orange active:text-kamadoor-orange"
        href="mailto:sklep@kamadoor.pl"
      >
        <i class="text-xl fa-at"></i>
        <span class="text-right text-xl font-extrabold">
          sklep&#64;kamadoor.pl
        </span>
      </a>
    </div>
  </div>
</div>
<p-menubar [model]="items">
  <ng-template pTemplate="item" let-item let-root="root">
    @if (item.url) {
    <a
      pRipple
      [ngClass]="{
        'active-item':
          activeItem === item.id || (item.id === 'offer' && isOfferItemSelected)
      }"
      [href]="item.url"
      class="flex align-items-center p-menuitem-link"
    >
      <span [class]="item.icon"></span>
      <span class="ml-2">{{ item.label }}</span>
      @if (item.items) {
      <i class="pi pi-angle-down ml-2"></i>
      }
    </a>
    } @else {
    <a
      pRipple
      [ngClass]="{
        'active-item':
          activeItem === item.id || (item.id === 'offer' && isOfferItemSelected)
      }"
      class="flex align-items-center p-menuitem-link"
    >
      <span [class]="item.icon"></span>
      <span class="ml-2">{{ item.label }}</span>
      @if (item.items) {
      <i class="pi pi-angle-down ml-2"></i>
      }
    </a>
    }
  </ng-template>
</p-menubar>
