import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-dre-berge',
  templateUrl: './berge.component.html',
  styleUrls: ['./berge.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class DreBergeComponent {
  items = [
    {
      label: 'Berge 1',
      image: '1.jpg',
    },
    {
      label: 'Berge 2',
      image: '2.jpg',
    },
    {
      label: 'Berge 3',
      image: '3.jpg',
    },
    {
      label: 'Berge 4',
      image: '4.jpg',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi wewnętrzne DRE Berge | KamaDOOR');
  }
}
