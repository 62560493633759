import { Component, Input } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { Carousel, CarouselModule } from 'primeng/carousel';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'kamadoor-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  imports: [ButtonModule, CarouselModule, ImageModule],
  standalone: true,
})
export class GalleryComponent {
  @Input() images!: any[];

  activeIndex = 0;

  constructor() {
    Carousel.prototype.onTouchMove = () => {};
  }
}
