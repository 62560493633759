import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-dre-standard',
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class DreStandardComponent {
  items = [
    {
      label: 'Standard 10',
      image: '10.jpg',
    },
    {
      label: 'Standard 20',
      image: '20.jpg',
    },
    {
      label: 'Standard 20s',
      image: '20s.jpg',
    },
    {
      label: 'Standard 30',
      image: '30.jpg',
    },
    {
      label: 'Standard 30s',
      image: '30s.jpg',
    },
    {
      label: 'Standard 40',
      image: '40.jpg',
    },
    {
      label: 'Standard 40s',
      image: '40s.jpg',
    },
    {
      label: 'Standard 50',
      image: '50.jpg',
    },
    {
      label: 'Standard 50s',
      image: '50s.jpg',
    },
    {
      label: 'Standard 60',
      image: '60.jpg',
    },
    {
      label: 'Bulaj MDF',
      image: 'mdf.jpg',
    },
    {
      label: 'Bulaj metalowy',
      image: 'metal.jpg',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi wewnętrzne DRE Standard | KamaDOOR');
  }
}
