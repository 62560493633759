<div
  class="flex flex-col whitespace-pre-wrap w-[240px] h-[240px] desktopLarge:w-[300px] desktopLarge:h-[300px]"
>
  <a
    [href]="url"
    [class]="
      'bg-' +
      bgColor +
      ' border border-white flex flex-col h-full transition ease-in-out duration-300 hover:-translate-y-1 hover:scale-110 text-center items-center'
    "
  >
    @if(showFooter) {
    <span
      class="h-[25%] text-kamadoor-orange font-semibold bg-black text-xl w-full"
      >{{ label }}</span
    >
    <div class="flex justify-center h-[65%]">
      <img class="object-contain max-h-full p-2" [src]="src" [alt]="label" />
    </div>

    <span class="h-[10%] bg-black text-kamadoor-orange text-lg w-full"
      >Zobacz ofertę</span
    >
    } @else {
    <span
      class="h-[20%] text-kamadoor-orange font-semibold bg-black text-xl w-full"
      >{{ label }}</span
    >
    <div class="flex justify-center h-[80%]">
      <img class="object-fill max-h-full" [src]="src" [alt]="label" />
    </div>
    }
  </a>
</div>
