<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Drzwi zewnętrzne na wymiar DALLAS
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span>
      Drzwi zewnętrzne <b>DALLAS</b> to wysokiej jakości ciepłe drzwi
      <b>DREWNIANE</b> obłogowane dębem występujące w kilku wariantach
      konstrukcyjnych:
      <ul class="list-disc ml-10 mt-4 flex flex-col gap-4">
        <li>
          ENERGOOSZCZĘDNE o grubości skrzydła 100mm<br />współczynnik
          przenikania ciepła U=0,49 W/(M²*K)
        </li>
        <li>
          PŁYCINOWE przylgowe o grubości skrzydła 75mm<br />współczynnik
          przenikania ciepła U=0,8 W/(M²*K)
        </li>
        <li>
          RAMIAKOWE przylgowe o grubości skrzydła 75mm<br />współczynnik
          przenikania ciepła U=1,1 W/(M²*K)
        </li>
      </ul></span
    >
    <span>Drzwi zewnętrzne występują również w wersji bezprzylgowej.</span>
    <span
      >Oferowane drzwi produkowane są <b>na wymiar</b> z możliwością
      <b>modyfikacji</b> wybranego modelu.</span
    >
    <span
      >Pełna oferta drzwi jest dostępna w
      <a
        class="text-kamadoor-orange hover:underline"
        href="https://www.dallas-drzwi.pl/do-pobrania/"
        >katalogu.</a
      ></span
    >

    <span
      >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
      pisemnego zapytania przy użyciu
      <a
        href="kontakt/formularz-kontaktowy"
        class="text-kamadoor-orange hover:underline"
        >FORMULARZA KONTAKTOWEGO.</a
      ></span
    >
  </div>

  <div class="mt-10 flex flex-col w-full">
    <span class="text-white font-semibold pb-2"
      >Poniżej przedstawiamy wybrane modele tego producenta:</span
    >
  </div>

  <div
    class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 desktopSmall:grid-cols-4 justify-between gap-y-8"
  >
    @for (number of numbers; track number) {
    <kamadoor-image-preview
      [src]="
        'assets/offer/external/dallas/DZD-' +
        number.toString().padStart(2, '0') +
        '.JPG'
      "
      [label]="'DZD-' + number.toString().padStart(2, '0')"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    }
  </div>
  <div
    class="grid grid-flow-row grid-cols-1 desktopSmall:grid-cols-3 justify-between gap-y-8 mt-10"
  >
    <kamadoor-image-preview
      src="
          assets/offer/external/dallas/DZD-21.JPG
        "
      label="DZD-21"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    <kamadoor-image-preview
      src="
          assets/offer/external/dallas/DZD-22.JPG
        "
      label="DZD-22"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    <kamadoor-image-preview
      src="
          assets/offer/external/dallas/DZD-23.JPG
        "
      label="DZD-23"
      labelClass="pt-4"
    ></kamadoor-image-preview>
  </div>
</div>
