import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { catchError, EMPTY, finalize, of } from 'rxjs';

import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';

import { MailingService } from '@services/mailing.service';

@Component({
  selector: 'kamadoor-contact-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    ToastModule,
  ],
  providers: [MailingService, MessageService],
})
export class ContactFormComponent implements OnInit {
  private readonly formBuilder = inject(FormBuilder);
  private readonly mailingService = inject(MailingService);
  private readonly messageService = inject(MessageService);

  formGroup!: FormGroup;
  buttonDisabled = false;

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Formularz kontaktowy | KamaDOOR');
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      client: [null, Validators.required],
      city: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      email: [
        null,
        [
          Validators.required,
          Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/),
        ],
      ],
      question: [null, Validators.required],
    });
  }

  sendMail(): void {
    this.buttonDisabled = true;
    const message = this.prepareMessage();

    this.mailingService
      .sendEmail(message)
      .pipe(
        catchError((error) => {
          if (error.status === 200) {
            return of(null);
          }

          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: 'Błąd',
            detail: `Podczas wysyłki maila wystąpił błąd!`,
          });

          return EMPTY;
        }),
        finalize(() => {
          this.buttonDisabled = false;
        })
      )
      .subscribe(() => {
        this.formGroup.reset();
        this.messageService.clear();
        this.messageService.add({
          severity: 'success',
          summary: 'Sukces',
          detail: `Email został wysłany!`,
        });
      });
  }

  prepareMessage(): object {
    const formValues = this.formGroup.getRawValue();

    const formData = new FormData();

    // Dodaj dane do FormData
    formData.append('submitted[firma___osoba_kontaktowa]', formValues.client);
    formData.append('submitted[miejscowosc]', formValues.city);
    formData.append('submitted[telefon_kontaktowy]', formValues.phoneNumber);
    formData.append('submitted[e_mail]', formValues.email);
    formData.append('submitted[zapytanie]', formValues.question);
    formData.append('details[sid]', '');
    formData.append('details[page_num]', '1');
    formData.append('details[page_count]', '1');
    formData.append('details[finished]', '0');
    formData.append('form_build_id', `form-${this.generateUniqueId()}`);
    formData.append('form_id', `webform_client_form_28`);
    formData.append('op', 'Wyślij zapytanie');

    return formData;
  }

  private generateUniqueId(): string {
    const array = new Uint8Array(22);
    window.crypto.getRandomValues(array);
    let uniqueId = btoa(String.fromCharCode(...array));
    uniqueId = uniqueId.replace(/\+/g, '');
    uniqueId = uniqueId.replace(/\//g, '');
    uniqueId = uniqueId.substring(0, 43);
    return uniqueId;
  }
}
