import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImageModule } from 'primeng/image';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-hidden-doors',
  templateUrl: './hidden-doors.component.html',
  styleUrls: ['./hidden-doors.component.scss'],
  standalone: true,
  imports: [ImagePreviewComponent, ImageModule],
})
export class HiddenDoorsComponent {
  numbers = Array.from({ length: 28 }, (_, i) => i + 1);

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi ukryte | KamaDOOR');
  }
}
