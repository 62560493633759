import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-dre-wood',
  templateUrl: './wood.component.html',
  styleUrls: ['./wood.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class DreWoodComponent {
  items = [
    {
      label: 'Wood M1 - W1',
      image: 'm1w1.jpg',
    },
    {
      label: 'Wood M1 - W2',
      image: 'm1w2.jpg',
    },
    {
      label: 'Wood M2 - W1',
      image: 'm2w1.jpg',
    },
    {
      label: 'Wood M3 - W1',
      image: 'm3w1.jpg',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi wewnętrzne DRE Wood | KamaDOOR');
  }
}
