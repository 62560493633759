import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { DataViewModule } from 'primeng/dataview';

import { OfferButtonComponent } from '@components/offer-button/offer-button.component';

@Component({
  selector: 'kamadoor-apartment-internal-doors',
  templateUrl: './apartment-internal-doors.component.html',
  styleUrls: ['./apartment-internal-doors.component.scss'],
  imports: [DataViewModule, OfferButtonComponent],
  standalone: true,
})
export class ApartmentInternalDoorsComponent {
  offerItems = [
    {
      name: 'Drzwi wejściowe\nBARAŃSKI',
      image: 'baranski.jpg',
      url: 'drzwi-wejsciowe-do-mieszkania-baranski',
    },
    {
      name: 'Drzwi wejściowe\nna wymiar DALLAS',
      image: 'dallas.jpg',
      url: 'drzwi-wejsciowe-do-mieszkania-dallas',
    },
    {
      name: 'Drzwi\nPRZECIWPOŻAROWE',
      image: 'fireproof.jpg',
      url: 'drzwi-wejsciowe-do-mieszkania-przeciwpozarowe',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi wejściowe do mieszkania | KamaDOOR');
  }
}
