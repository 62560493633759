import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-dre-shiny',
  templateUrl: './shiny.component.html',
  styleUrls: ['./shiny.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class DreShinyComponent {
  items = [
    {
      label: 'Silia 10',
      image: 'silia-10.jpg',
    },
    {
      label: 'Vetro D2',
      image: 'vetro-d2.jpg',
    },
    {
      label: 'Ilis 4',
      image: 'ilis4.jpg',
    },
    {
      label: 'Ilis 3',
      image: 'ilis3.jpg',
    },
    {
      label: 'Nella 1',
      image: 'nella1.jpg',
    },
    {
      label: 'Nella 3',
      image: 'nella3.jpg',
    },
    {
      label: 'Lux 10',
      image: 'lux10.jpg',
    },
    {
      label: 'Lux 20',
      image: 'lux20.jpg',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi wewnętrzne DRE połyskowe | KamaDOOR');
  }
}
