<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Drzwi wewnętrzne BARAŃSKI
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span>
      Drzwi wewnętrzne Barański to produkty wysokiej jakości, zachwycające nie
      tylko swoją solidnością, ale również estetyką i trwałością.
    </span>
    <span>
      W naszym salonie sprzedaży można znaleźć drzwi wewnętrzne Barański w
      różnych wzorach i kolorach. Dysponujemy modelami ze szkleniem oraz bez.
      Posiadamy produkty w jednolitych barwach np. bieli, a także fornirowane. W
      naszym asortymencie można znaleźć drzwi wewnętrzne Barański utrzymane w
      różnych stylach. Posiadamy modele idealne do przestrzeni klasycznych,
      nowoczesnych i urządzonych w nieco bardziej wyszukanej stylistyce.
    </span>
    <span
      >Pełna oferta drzwi jest dostępna w
      <a
        class="text-kamadoor-orange hover:underline"
        href="https://www.baranskidrzwi.pl/katalogi/"
        >katalogu.</a
      ></span
    >

    <span
      >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
      pisemnego zapytania przy użyciu
      <a
        href="kontakt/formularz-kontaktowy"
        class="text-kamadoor-orange hover:underline"
        >FORMULARZA KONTAKTOWEGO.</a
      ></span
    >
  </div>

  <div class="mt-10 flex flex-col w-full">
    <span class="text-white font-semibold pb-2"
      >Poniżej przedstawiamy wybrane modele tego producenta:</span
    >
  </div>

  <div
    class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 desktopSmall:grid-cols-4 justify-between gap-y-8"
  >
    @for (number of numbers; track number) {
    <kamadoor-image-preview
      [src]="
        'assets/offer/internal/baranski/DWB-' +
        number.toString().padStart(2, '0') +
        '.jpg'
      "
      [label]="'DWB-' + number.toString().padStart(2, '0')"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    }
  </div>
</div>
