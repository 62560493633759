import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-dre-binito',
  templateUrl: './binito.component.html',
  styleUrls: ['./binito.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class DreBinitoComponent {
  items = [
    {
      label: 'Binito 10',
      image: '10.jpg',
    },
    {
      label: 'Binito 20',
      image: '20.jpg',
    },
    {
      label: 'Binito 21',
      image: '21.jpg',
    },
    {
      label: 'Binito 24',
      image: '24.jpg',
    },
    {
      label: 'Binito 30',
      image: '30.jpg',
    },
    {
      label: 'Binito 50',
      image: '50.jpg',
    },
    {
      label: 'Binito 60',
      image: '60.jpg',
    },
    {
      label: 'Binito 70',
      image: '70.jpg',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi wewnętrzne DRE Binito | KamaDOOR');
  }
}
