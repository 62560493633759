import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-loft-vestibule',
  templateUrl: './vestibule.component.html',
  styleUrls: ['./vestibule.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class LoftVestibuleComponent {
  numbers = Array.from({ length: 92 }, (_, i) => i + 1);

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi szklane do wiatrołapu | KamaDOOR');
  }
}
