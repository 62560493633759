<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Drzwi Loftowe
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span class="text-2xl">
      Naszą specjalnością produktową są Drzwi Loftowe – Szklane – Wahadłowe –
      Rozwierane.
    </span>
    <span class="font-semibold">
      Drzwi Loftowe wykonujemy pod wymiar na indywidualne zamówienie.
    </span>
    <span
      >Na etapie podjęcia współpracy z naszą firmą, dokonujemy pomiaru otworu
      drzwiowego i doradzamy w zakresie projektu oraz określamy wraz z klientem
      indywidualne cechy techniczne i wizualne drzwi w taki sposób, aby produkt
      końcowy w pełni spełnił oczekiwania zamawiającego.</span
    >
    <span
      >Po wyprodukowaniu zabudowy drzwiowej, dostarczamy ją na miejsce
      przeznaczenia oraz przeprowadzamy jej montaż.</span
    >
    <span
      >Poniżej prezentujemy przykładowe zdjęcia różnego typu drzwi Loftowych -
      Industrialnych, jakie wykonaliśmy dla naszych klientów.</span
    >
    <span
      >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
      pisemnego zapytania przy użyciu
      <a
        href="kontakt/formularz-kontaktowy"
        class="text-kamadoor-orange hover:underline"
        >FORMULARZA KONTAKTOWEGO.</a
      ></span
    >
    <span
      >Prosimy w Formularzu wypełnić wszystkie wymagane pola oraz wskazać numer
      zdjęcia z drzwiami, jakie są najbliższe Państwa oczekiwaniom oraz podać
      orientacyjny wymiar otworu drzwiowego - jego szerokość oraz
      wysokość.</span
    >
    <span
      >Posiadamy ponad 10 Letnie Doświadczenie w Branży Stolarki
      Budowlanej.</span
    >
  </div>

  <div
    class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 desktopSmall:grid-cols-4 justify-between gap-y-8"
  >
    @for (number of numbers; track number) {
    <kamadoor-image-preview
      [src]="'assets/offer/loft/loft/' + number.toString() + '.jpg'"
      [label]="'DL-' + number.toString().padStart(2, '0')"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    }
  </div>

  <div
    class="grid grid-flow-row grid-cols-1 desktopSmall:grid-cols-3 justify-between gap-y-8 mt-10"
  >
    <kamadoor-image-preview
      src="
          assets/offer/loft/loft/117.jpg
        "
      label="DL-117"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    <kamadoor-image-preview
      src="
          assets/offer/loft/loft/118.jpg
        "
      label="DL-118"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    <kamadoor-image-preview
      src="
          assets/offer/loft/loft/119.jpg
        "
      label="DL-119"
      labelClass="pt-4"
    ></kamadoor-image-preview>
  </div>
</div>
