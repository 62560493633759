import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-dre-nestor',
  templateUrl: './nestor.component.html',
  styleUrls: ['./nestor.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class DreNestorComponent {
  items = [
    {
      label: 'Nestor 1',
      image: '1.jpg',
    },
    {
      label: 'Nestor 2',
      image: '2.jpg',
    },
    {
      label: 'Nestor 3',
      image: '3.jpg',
    },
    {
      label: 'Nestor 4',
      image: '4.jpg',
    },
    {
      label: 'Nestor 5',
      image: '5.jpg',
    },
    {
      label: 'Nestor 6',
      image: '6.jpg',
    },
    {
      label: 'Nestor 10',
      image: '10.jpg',
    },
    {
      label: 'Nestor 11',
      image: '11.jpg',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi wewnętrzne DRE Nestor | KamaDOOR');
  }
}
