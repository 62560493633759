<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Ścianki Prysznicowe - Kabiny Prysznicowe
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span>
      Jesteśmy firmą, wykonującą swe produkty
      <b>WYŁĄCZNIE NA INDYWIDUALNE ZAMÓWIENIE KLIENTÓW.</b>
    </span>

    <span>
      Zdarza się, iż istnieje potrzeba opracowania oraz wykonania szklanej
      zabudowy w postaci ścianki prysznicowej lub szyb dla kabiny prysznicowej
      "na wymiar" o nieco innym kształcie a także projekcie wizualnym, niż
      powszechnie spotykane. I w tym momencie Klienci, którzy zwrócili się do
      naszej firmy o pomoc w tym zakresie, mogą liczyć na wykonanie tego typu
      elementu wyposażenia łazienki.
    </span>

    <span
      >Zabudowy kabin, czy np. pryszniców typu walk in, wykonujemy WYŁĄCZNIE Z
      UŻYCIEM NAJWYŻSZEJ JAKOŚCI KOMPONENTÓW oraz bezpiecznych - hartowanych
      szyb.</span
    >

    <span
      >Szklane zabudowy do łazienek, wykonujemy w wielu wariantach
      konstrukcyjnych oraz wymiarowych.</span
    >

    <span
      >Jako producent,
      <b
        >ZAPEWNIAMY BEZKONKURENCYJNĄ CENĘ - POŁĄCZONĄ Z DOSKONAŁĄ JAKOŚCIĄ
        PRODUKTOWĄ.</b
      ></span
    >

    <span>Zainteresowanych wyrobami naszej firmy, zapraszamy do kontaktu.</span>

    <span
      >Po otrzymaniu od Państwa zapytania, przedstawimy bezpłatną ofertę cenową
      oraz udzielimy konsultacji technicznej.</span
    >

    <span
      >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
      pisemnego zapytania przy użyciu
      <a
        href="kontakt/formularz-kontaktowy"
        class="text-kamadoor-orange hover:underline"
        >FORMULARZA KONTAKTOWEGO.</a
      ></span
    >
  </div>

  <div class="mt-10 flex flex-col w-full">
    <span class="text-white font-semibold pb-2"
      >Poniżej prezentujemy przykładowe zdjęcia szklanych ścian i kabin
      prysznicowych z naszych realizacji.</span
    >
  </div>

  <div
    class="grid grid-flow-row grid-cols-1 tablet:grid-cols-2 desktopSmall:grid-cols-4 justify-between gap-y-8"
  >
    @for (number of numbers; track number) {
    <kamadoor-image-preview
      [src]="'assets/offer/showers/' + number.toString() + '.jpg'"
      labelClass="pt-4"
    ></kamadoor-image-preview>
    }
  </div>

  <div class="grid grid-flow-row grid-cols-1 justify-between gap-y-8 mt-10">
    <kamadoor-image-preview
      src="
          assets/offer/showers/29.jpg
        "
      labelClass="pt-4"
    ></kamadoor-image-preview>
  </div>
</div>
