import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { AccordionModule } from 'primeng/accordion';

@Component({
  selector: 'kamadoor-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss'],
  imports: [AccordionModule],
  standalone: true,
})
export class GuideComponent {
  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Warto wiedzieć | KamaDOOR');
  }
}
