<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Drzwi przeciwpożarowe
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span class="text-3xl"> DRZWI PRZECIWPOŻAROWE - EI 30 i EI 60 </span>
    <span class="text-2xl"
      >Przeciwpożarowe drzwi do Kamienic, Urzędów, Hoteli, Szpitali, Szkół,
      Bibliotek.
    </span>
    <span>
      Oferowane przez naszą firmę atestowane drzwi, mogą zostać wykonywane na
      indywidualne zamówienie klienta pod względem wymiarów, co daje pełną
      możliwość ich montażu zarówno w nowo powstających, jak i remontowanych
      obiektach.
    </span>
    <span>
      Drewniane skrzydła drzwi przeciwpożarowych, wykonane są między innymi z
      ognioodpornej płyty typu HALSPAN o grubości 52 mm, co jednocześnie pozwala
      uzyskać izolację akustyczną na poziomie 34 dB. Okleinę zewnętrzną drzwi,
      stanowi naturalny fornir dębowy lub jesionowy.
    </span>
    <span>
      <u>W standardowym wyposażeniu produkt posiada:</u>
      <ul class="list-disc ml-10 mt-2 flex flex-col gap-2">
        <li>uszczelkę ognioodporną pęczniejącą,</li>
        <li>uszczelkę ognioodporną dymoszczelną,</li>
        <li>uszczelkę akustyczną,</li>
        <li>zawiasy trzpieniowe 4 szt.,</li>
        <li>samozamykacz,</li>
        <li>próg opadający,</li>
        <li>zamek na wkładkę patentową,</li>
        <li>wkładkę patentową,</li>
        <li>klamkę,</li>
        <li>ościeżnicę stałą 100 x 55 cm,</li>
        <li>wymiary typowych szerokości drzwi : „80” - 910, „90” - 1010</li>
      </ul></span
    >
    <span>
      <u>Drzwi mogą zostać wyposażone dodatkowo w takie elementy jak:</u>
      <ul class="list-disc ml-10 mt-2 flex flex-col gap-2">
        <li>ościeżnicę regulowaną,</li>
        <li>elektrozaczep,</li>
        <li>elektrozaczep rewersyjny,</li>
        <li>dla drzwi bezprzylgowych - "zawiasy ukryte",</li>
        <li>samozamykacz ukryty</li>
      </ul></span
    >

    <span
      >Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
      pisemnego zapytania przy użyciu
      <a
        href="kontakt/formularz-kontaktowy"
        class="text-kamadoor-orange hover:underline"
        >FORMULARZA KONTAKTOWEGO.</a
      ></span
    >
  </div>

  <div class="flex flex-col gap-4">
    <p-image
      src="assets/offer/apartment-internal/fireproof/DPP-01.jpg"
      alt="Image"
      [preview]="true"
      imageClass="p-6"
    />
    <p-image
      src="assets/offer/apartment-internal/fireproof/DPP-02.jpg"
      alt="Image"
      [preview]="true"
      imageClass="p-6"
    />
    <p-image
      src="assets/offer/apartment-internal/fireproof/DPP-03.jpg"
      alt="Image"
      [preview]="true"
      imageClass="p-6"
    />
    <p-image
      src="assets/offer/apartment-internal/fireproof/DPP-04.jpg"
      alt="Image"
      [preview]="true"
      imageClass="p-6"
    />
    <p-image
      src="assets/offer/apartment-internal/fireproof/DPP-05.jpg"
      alt="Image"
      [preview]="true"
      imageClass="p-6"
    />
  </div>
</div>
