import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';

@Component({
  selector: 'kamadoor-dre-auri',
  templateUrl: './auri.component.html',
  styleUrls: ['./auri.component.scss'],
  imports: [ImagePreviewComponent],
  standalone: true,
})
export class DreAuriComponent {
  items = [
    {
      label: 'Auri 0',
      image: '0.jpg',
    },
    {
      label: 'Auri 1',
      image: '1.jpg',
    },
    {
      label: 'Auri 2',
      image: '2.jpg',
    },
    {
      label: 'Auri 3',
      image: '3.jpg',
    },
    {
      label: 'Auri 4',
      image: '4.jpg',
    },
    {
      label: 'Auri 5',
      image: '5.jpg',
    },
    {
      label: 'Auri 6',
      image: '6.jpg',
    },
    {
      label: 'Auri 7',
      image: '7.jpg',
    },
  ];

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Drzwi wewnętrzne DRE Auri | KamaDOOR');
  }
}
