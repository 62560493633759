<div class="flex flex-col bg-kamadoor-light-gray m-2 p-4">
  <span
    class="flex pb-2 w-full border-b-2 border-b-kamadoor-orange font-sans gap-2 text-4xl text-white"
  >
    Drzwi DRE
  </span>

  <div class="flex flex-col gap-4 font-sans mt-4 text-white">
    <span>
      Oferowane przez naszą firmę drzwi DRE mogą być wykonywane w wersji:
      <ul class="list-disc ml-10 mt-2 flex flex-col gap-2">
        <li>przylgowej</li>
        <li>bezprzylgowej</li>
        <li>przesuwnej</li>
      </ul>
    </span>
    <span>
      Skrzydła o konstrukcji ramowej lub płytowej mogą być malowane lakierem UV
      lub okleinowane laminatem.
    </span>
    <span>
      Chcąc uzyskać wstępną - przybliżoną wycenę, prosimy o złożenie nam
      pisemnego zapytania przy użyciu
      <a
        href="kontakt/formularz-kontaktowy"
        class="text-kamadoor-orange hover:underline"
        >FORMULARZA KONTAKTOWEGO.</a
      ></span
    >
    <span
      >Prosimy w formularzu wypełnić wszystkie wymagane pola, wskazać nam nazwę
      wybranego modelu drzwi oraz podać orientacyjny wymiar otworu drzwiowego -
      jego szerokość oraz wysokość oraz grubość muru.</span
    >
    <span
      >Pełna oferta drzwi jest dostępna w
      <a
        class="text-kamadoor-orange hover:underline"
        href="https://dre.pl/pliki-do-pobrania/"
        >katalogu.</a
      ></span
    >
  </div>

  <div class="mt-10 flex flex-col w-full">
    <span class="text-white font-semibold pb-2"
      >Poniżej przedstawiamy wybrane kolekcje tego producenta:</span
    >
  </div>

  <p-dataView [value]="offerItems" layout="grid">
    <ng-template let-product pTemplate="grid" let-items>
      <div
        class="grid grid-flow-row justify-items-center grid-cols-1 mobileLarge:grid-cols-2 desktopSmall:grid-cols-3 desktop:grid-cols-4 justify-between gap-y-4 bg-kamadoor-light-gray"
      >
        @for (item of items; track item.name) {
        <kamadoor-offer-button
          [label]="item.name"
          [src]="'assets/offer/internal/dre/' + item.image"
          [url]="item.url"
          [showFooter]="false"
        ></kamadoor-offer-button>
        }
      </div>
    </ng-template>
  </p-dataView>
</div>
